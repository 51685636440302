import { OptionType } from '../components/molecules/AdminFilters/Dropdown';
import { ClientStagesType, GoalTypeProps, WealthGoalType } from './types';

export const OKTA_DEVICE_TOKEN_KEY = document
    ? document?.location?.host + '-' + 'device-token'
    : 'advice-experience-device-token';

export const fontFamily = 'TT Commons Pro';

export const LOGIN = {
    MFA_CHALLENGE: 'MFA_CHALLENGE',
    MFA: 'MFA',
    SUCCESS: 'SUCCESS',
    MFA_REQUIRED: 'MFA_REQUIRED',
    OKTA: 'OKTA'
};

export const ACCESS_LEVEL_DROPDOWN_OPTIONS = (t: any) => [
    {
        value: 'advisor',
        label: t('TEXT_ADMIN_USER_FA_HEADER')
    },
    {
        value: 'admin',
        label: t('TEXT_ADMIN_USER_FA_ADMIN_HEADER')
    }
];

export const FINANCIAL_ADVISORS_ACCESS_KEYS = {
    ADVISOR_NAME: 'advisorName',
    ADVISOR_EMAIL: 'advisorEmail',
    NO_OF_CLIENTS: 'numberOfClients',
    NO_OF_GOALS: 'numberOfGoals',
    REMOVE: 'remove'
};

export const ADMIN_USER_ACCESS_KEYS = {
    ADMIN_NAME: 'adminName',
    ADMIN_EMAIL: 'adminEmail',
    REMOVE: 'remove'
};
// Create client steps
export const CLIENT_PROFILE_STAGE1 = 'personalInformation';
export const CLIENT_PROFILE_STAGE2 = 'relationships';
export const CLIENT_PROFILE_STAGE3 = 'riskTolerance';
export const CLIENT_PROFILE_STAGE4 = 'fundingSources';
// Create goal types
export const RETIREMENT_GOAL = 'RETIREMENT_GOAL';
export const WEALTH_GOAL = 'WEALTH_GOAL';

// add new stage/goal type keys here
// alter order below to rearrage quadrants on UI
const CLIENT_JOURNEY_STAGES: { CLIENT_PROFILE: ClientStagesType[]; GOALS: GoalTypeProps[] } = {
    CLIENT_PROFILE: [
        { order: 1, key: CLIENT_PROFILE_STAGE1, optional: false },
        { order: 2, key: CLIENT_PROFILE_STAGE2, optional: true },
        { order: 3, key: CLIENT_PROFILE_STAGE3, optional: false },
        { order: 4, key: CLIENT_PROFILE_STAGE4, optional: false }
    ],
    GOALS: [
        {
            order: 1,
            category: RETIREMENT_GOAL,
            heading: 'Retirement Goal',
            type: 'retirement',
            title: 'Retirement',
            disabled: false,
            backgroundColor: '#E7DFFE',
            darkBackground: '#B39AF5',
            color: '#4B00B6',
            icon: 'retirement'
        },
        {
            order: 1,
            category: WEALTH_GOAL,
            type: 'buyahouse',
            title: WealthGoalType.BUY_A_HOUSE,
            heading: 'Create Buy a House Goal',
            disabled: false,
            backgroundColor: '#FFDDE6',
            darkBackground: '#F589A2',
            color: '#DC0546',
            icon: 'house'
        },
        {
            order: 3,
            category: WEALTH_GOAL,
            type: 'buyacar',
            title: WealthGoalType.BUY_A_CAR,
            heading: 'Create Buy a Car Goal',
            disabled: false,
            backgroundColor: '#FFE0D2',
            darkBackground: '#FF9665',
            color: '#BE3200',
            icon: 'car'
        },
        {
            order: 2,
            category: WEALTH_GOAL,
            type: 'payforcollege',
            title: WealthGoalType.PAY_FOR_COLLEGE,
            heading: 'Create Pay for College Goal',
            disabled: false,
            backgroundColor: '#FAE4FD',
            darkBackground: '#F0B4F9',
            color: '#A525D0',
            icon: 'college'
        },
        {
            order: 4,
            category: WEALTH_GOAL,
            type: 'custom',
            title: WealthGoalType.CUSTOM,
            heading: 'Create Custom Goal',
            disabled: false,
            backgroundColor: '#DDEAFF',
            darkBackground: '#91B9FF',
            color: '#1446E1',
            icon: 'custom'
        }
    ]
    // APPROACH: [],
    // PLAN_DETAILS: [],
    // PROPOSAL: []
};

export const CLIENT_PROFILE_STAGE_KEYS = CLIENT_JOURNEY_STAGES.CLIENT_PROFILE.sort(
    (stage1: ClientStagesType, stage2: ClientStagesType) => Number(stage1.order) - Number(stage2.order)
).map((stage) => stage.key);

export const CLIENT_PROFILE_STAGES = CLIENT_JOURNEY_STAGES.CLIENT_PROFILE.sort(
    (stage1: ClientStagesType, stage2: ClientStagesType) => Number(stage1.order) - Number(stage2.order)
);

export const RETIREMENT_CATEGORY_GOALS = CLIENT_JOURNEY_STAGES.GOALS.filter(
    (g: GoalTypeProps) => g.category === RETIREMENT_GOAL
).sort((goal1: GoalTypeProps, goal2: GoalTypeProps) => Number(goal1.order) - Number(goal2.order));

export const WEALTH_CATEGORY_GOALS = CLIENT_JOURNEY_STAGES.GOALS.filter(
    (g: GoalTypeProps) => g.category === WEALTH_GOAL
).sort((goal1: GoalTypeProps, goal2: GoalTypeProps) => Number(goal1.order) - Number(goal2.order));

export const MAX_ALLOWED_GOALS = 6;

export const PORTFOLIO_COMPOSITION_COLOR = {
    equityColor: [
        '#00bfb3',
        '#1ac6bb',
        '#33ccc2',
        '#4dd3ca',
        '#66d9d1',
        '#7fded8',
        '#99e5e1',
        '#b3ece9',
        '#ccf2f0',
        '#e6f9f8'
    ],
    fixedIncomeColor: [
        '#6730e3',
        '#7745e6',
        '#8559e9',
        '#956fec',
        '#a483ee',
        '#c2acf4',
        '#d2c1f7',
        '#e1d6f9',
        '#f0ebfd'
    ],
    alternativesColor: ['#5291ff', '#649cff', '#75a7ff', '#97bdff', '#a8c7ff'],
    allPortfoliosBaseColors: ['#00BFB3', '#6730E3', '#5291FF']
};

export const iwsGoalTypeMapper: any = {
    WEALTH_GOAL: 'regular',
    retirement: 'retirement' //need to confirm
};

export const statusOptions: OptionType<string | undefined>[] = [
    {
        value: undefined,
        label: 'All'
    },
    {
        value: 'incomplete',
        label: 'Incomplete'
    },
    {
        value: 'complete',
        label: 'Complete'
    }
];

export const riskProfileOptions: OptionType<string | undefined>[] = [
    {
        value: undefined,
        label: 'All'
    },
    {
        value: 'Conservative',
        label: 'Conservative'
    },
    {
        value: 'Moderate',
        label: 'Moderate'
    },
    {
        value: 'Aggressive',
        label: 'Aggressive'
    }
];

export const typeOptions: OptionType<string | undefined>[] = [
    {
        value: undefined,
        label: 'All'
    },
    {
        value: 'WEALTH_GOAL',
        label: 'Wealth'
    },
    {
        value: 'retirement',
        label: 'Retirement'
    }
];

export const priorityOptions: OptionType<string | undefined>[] = [
    {
        value: undefined,
        label: 'All'
    },
    {
        value: 'need',
        label: 'Need'
    },
    {
        value: 'want',
        label: 'Want'
    },
    {
        value: 'wish',
        label: 'Wish'
    },
    {
        value: 'dream',
        label: 'Dream'
    }
];

export const API_VERSION = 'v1369';

export const allowedDomainNameOrigins = [
    'https://www.franklintempletonoffshore.com',
    'https://www.franklintempleton.com'
];

export const OKTA_LOGIN_URL = 'https://ftext.okta.com/oauth2/default';
