import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../common/hooks';
import { AlertDialog } from '../../components/molecules/AlertDialog/AlertDialog';
// import { toggleSessionExpiredModal } from '../../features/auth/authSlice';
import { Footer } from '../../components/molecules';
import OktaLoginWidget from '../../features/login-okta-widget';
// import { useDispatch } from 'react-redux';

export const WelcomText = styled(Typography)(({ theme }) => ({
    color: theme.palette.background.default
}));

const PageContainer: any = styled('div')(() => ({
    display: 'grid',
    gridTemplateRows: '1fr auto',
    height: '100vh'
}));

const MainContent: any = styled('main')(() => ({
    display: 'grid',
    gridTemplateColumns: '60% 40%'
}));

const LeftContainer = styled('section')(({ theme }) => ({
    '.MuiInputBase-input': {
        boxSizing: 'border-box'
    },
    backgroundColor: theme.palette.primary.main,
    alignItems: 'flex-start',
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
    padding: '0 10%'
}));

const RightContainer: any = styled('section')(({ theme }) => ({
    alignItems: 'flex-start',
    display: 'flex',
    flexDirection: 'column',
    padding: '20px',
    justifyContent: 'space-evenly',
    backgroundColor: theme.palette.primary.main
}));

const LoginContainer = () => {
    const { t } = useTranslation();
    // const dispatch = useDispatch();
    const showSessionExpiredModal = useAppSelector((state) => state.auth.showSessionExpiredModal);
    const [showModal, setShowModal] = useState(showSessionExpiredModal);

    const handleCloseModal = () => {
        // dispatch(toggleSessionExpiredModal(false));
        setShowModal(false);
    };

    const footerArgs = {
        primaryText: `Copyright © ${new Date().getFullYear()} Franklin Templeton. All Rights Reserved.`,
        links: [
            {
                label: 'Terms of Use',
                link: `${process.env.PUBLIC_URL}/assets/pdf/en/Digital_Advisor_Terms_of_Use.pdf`
            },
            { label: 'Privacy Notice', link: 'https://www.franklintempletonglobal.com/privacy' }
        ]
    };

    return (
        <PageContainer>
            <MainContent>
                <LeftContainer>
                    <WelcomText variant="h2">{t('TEXT_LOGIN_SCREEN_WELCOME_TEXT_1')}</WelcomText>
                    <WelcomText variant="h1" sx={{ fontWeight: 600 }}>
                        {t('TEXT_LOGIN_SCREEN_WELCOME_TEXT_2')}
                    </WelcomText>
                    <WelcomText variant="h5">{t('TEXT_LOGIN_SCREEN_WELCOME_TEXT_3')}</WelcomText>
                </LeftContainer>

                <RightContainer item xs={12} sm={12} md={5} lg={5}>
                    <OktaLoginWidget />
                </RightContainer>
            </MainContent>

            <Footer eulaVariant={false} showDisclaimerFooter={false} {...footerArgs} />

            <AlertDialog
                handleClose={handleCloseModal}
                open={showModal}
                content={`${t('SESSION_EXPIRED_MODAL_CONTENT')}`}
                title={t('SESSION_EXPIRED_MODAL_TITLE')}
                aria-label="close-session-expired-popup"
                data-testid="session-expired-modal-close-button"
            />
        </PageContainer>
    );
};

export default LoginContainer;
