import { styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Typography, TypographyProps } from '../../components/atoms/Typography/Typography';
import { Tabs } from '../../components/molecules/Tabs/Tabs';
import { AdminClientsTab } from '../../containers/admin-view/AdminClientsTab';
import { AdminSummaryTab } from '../../containers/admin-view/AdminSummaryTab';
import { AdminUserManagement } from '../../containers/admin-view/AdminUserManagement';
import { useState } from 'react';

const PageContainer: any = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'column',
    padding: '10px 40px 10px 40px',
    flexGrow: 1
}));

export const HeaderText = styled(Typography)<TypographyProps>(({ theme }) => ({
    color: theme.palette.primary.dark,
    margin: `${theme.spacing(4)} 0`,
    display: 'inline-block'
}));

const Admin = () => {
    const { t } = useTranslation();
    const [tabIndex, setTabIndex] = useState('0');

    const tabPanels = [
        { enabled: true, title: t('TEXT_ADMIN_TAB_1'), hidden: false, children: <AdminSummaryTab /> },
        { enabled: true, title: t('TEXT_ADMIN_TAB_2'), hidden: false, children: <AdminClientsTab /> },
        { enabled: true, title: t('TEXT_ADMIN_TAB_3'), hidden: false, children: <AdminUserManagement /> }
    ];

    const handleTabChange = (event: React.SyntheticEvent, value: string) => {
        setTabIndex(value);
        return value;
    };

    return (
        <PageContainer>
            <HeaderText tabIndex={0} variant="h1" label={t('TEXT_ADMIN_DASHBOARD_HEADER')} />
            <Tabs tabPanels={tabPanels} value={tabIndex} onChange={handleTabChange} />
        </PageContainer>
    );
};

export default Admin;
