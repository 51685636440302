import { OktaAuth } from '@okta/okta-auth-js';
import { PayloadAction, createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';

import { OKTA_DEVICE_TOKEN_KEY, OKTA_LOGIN_URL } from '../../common/constants';
import { uipApiInstance } from '../../services';
import { getOrganizationConfig, logEventToBackEnd } from '../global/globalSlice';

type InitialState = {
    isAuthenticated: boolean;
    user: any;
    loading: boolean;
    isSendingMFA: boolean;
    error?: null | undefined | any;
    showSessionExpiredModal: boolean;
    sendingForgotPassword?: boolean;
    isHantzUserSettings: boolean;
    oktaState: any;
};

const initialState: InitialState = {
    isAuthenticated: false,
    user: {},
    loading: false,
    isSendingMFA: false,
    error: null,
    showSessionExpiredModal: false,
    sendingForgotPassword: false,
    isHantzUserSettings: false,
    oktaState: {}
};

/** Function to addLogEvent */
export const addLogEvent = async (
    apiURL: string,
    requestType: 'request' | 'response',
    reqBody: object | unknown,
    status?: string
) => {
    let data: any = { api_url: apiURL, request_type: requestType, api_request: reqBody };
    if (requestType === 'response' && status) data = { status, ...data };
    else data = { status: '', ...data };
    try {
        await uipApiInstance({
            method: 'POST',
            url: '/api/advice/addLogEvent',
            withCredentials: false,
            data
        });
    } catch (err) {
        console.log(err);
    }
};

const getLoginUserInformation = async (dispatch: any, getState: any) => {
    const state: any = getState();
    const tenantId = state?.global?.globalConfig?.subdomain;
    const accessToken = state?.auth?.oktaState?.accessToken?.accessToken;
    const oktaState = state?.auth?.oktaState;

    const userInfoRes: any = await axios({
        method: 'GET',
        baseURL: process.env.REACT_APP_UIP_API_URL,
        url: '/api/advice/loginuserinfo',
        withCredentials: false,
        headers: {
            Authorization: 'Bearer ' + accessToken
        }
    });

    const userInfo = {
        lastLoggedInDateTime: userInfoRes?.data?.lastLogin,
        loggedInUsername: userInfoRes?.data?.name,
        userRole: userInfoRes?.data?.role,
        userId: oktaState?.idToken?.claims?.sub,
        userEmail: oktaState?.idToken?.claims?.email,
        // accessToken: accessToken, // CRITICAL ITEM, NOT TO BE SAVED LOCALLY
        accessTokenExpiration: oktaState?.accessToken?.expiresAt,
        // idToken: oktaState?.idToken?.idToken, // CRITICAL ITEM, NOT TO BE SAVED LOCALLY
        tenant: userInfoRes?.data.tenant,
        tenants: userInfoRes?.data.tenants,
        freemium: userInfoRes?.data.freemium,
        clickWrapAgreements: userInfoRes?.data.clickWrapAgreements
    };

    if (tenantId !== userInfo.tenant && !userInfo.tenants?.includes(tenantId)) {
        return false;
    }

    /** addLogEvent for Successful Authentication by Okta */
    await addLogEvent(
        OKTA_LOGIN_URL,
        'response',
        {
            userId: userInfo?.userId,
            userRole: userInfo.userRole,
            userName: userInfo.userEmail,
            tenant: userInfo.tenant
        },
        'success'
    );

    const orgInfo = await dispatch(getOrganizationConfig());
    const hantz = orgInfo?.payload?.orgInfo?.hantz;
    const userAgreement = orgInfo?.payload?.orgInfo?.userAgreement;
    return { ...userInfo, hantz, userAgreement };
};

export const getLoggedInUserInfo = createAsyncThunk(
    'authState/getLoggedInUserInfo',
    async (_, { dispatch, rejectWithValue, getState }) => {
        try {
            let user: any = {};
            user = await getLoginUserInformation(dispatch, getState);
            return user;
        } catch (error) {
            dispatch(logEventToBackEnd(`GET_LOGIN_USER_INFO_API_ERROR: ${error}`));
            return rejectWithValue(error);
        }
    }
);

export const updateHantzUserSettings = createAsyncThunk(
    'authState/updateHantzUserSettings',
    async (any, { rejectWithValue, dispatch }) => {
        try {
            const response = await uipApiInstance({
                method: 'POST',
                url: '/api/advice/update_user_settings',
                withCredentials: false,
                data: {
                    accepted: false
                }
            });
            const data = response.status;
            if (data === 200) return true;
        } catch (error) {
            console.error('Error while calling update_user_settings', error);
            dispatch(logEventToBackEnd('UPDATE_USER_SETTINGS_API_ERROR'));
            return rejectWithValue(error);
        }
    }
);

type UserInfo = { oktaAuth: OktaAuth; username: string; password: string };

let transaction: any;
let factor: any;
let deviceTokenFromLocalStorage: any;

export const authenticateUser = createAsyncThunk(
    'authState/authenticateUser',
    async ({ oktaAuth, username, password }: UserInfo, { rejectWithValue, dispatch, getState }) => {
        try {
            // const state: any = getState();

            const stateToken = Math.random().toString(36).substring(2); // Generate random state
            sessionStorage.setItem('oktaState', stateToken); // Store in session

            // const tenantId = state?.global?.globalConfig?.subdomain;

            deviceTokenFromLocalStorage = await localStorage.getItem(OKTA_DEVICE_TOKEN_KEY);
            if (!deviceTokenFromLocalStorage) {
                const uuid = uuidv4();
                localStorage.setItem(OKTA_DEVICE_TOKEN_KEY, uuid.substring(0, 32));
                deviceTokenFromLocalStorage = uuid.substring(0, 32);
            }
            // log authn to backend
            await addLogEvent(OKTA_LOGIN_URL, 'request', {
                username,
                deviceToken: deviceTokenFromLocalStorage || ''
            });
            transaction = await oktaAuth.signInWithCredentials({
                username,
                password,
                ...(deviceTokenFromLocalStorage && {
                    context: {
                        deviceToken: deviceTokenFromLocalStorage || ''
                    }
                }),
                relayState: stateToken // Pass state for verification
            });

            // If status = "MFA_REQUIRED"
            if (transaction?.status === 'MFA_REQUIRED' || transaction?.status === 'MFA_ENROLL') {
                // Send MFA code to preferred MFA option
                factor = transaction?.factors?.find?.(
                    (fac: any) => fac?.provider === 'OKTA' && fac?.factorType === 'email'
                );
                await addLogEvent(
                    OKTA_LOGIN_URL,
                    'response',
                    { factorid: factor?.id, username, deviceToken: deviceTokenFromLocalStorage || '' },
                    'success'
                );
                // eslint-disable-next-line require-atomic-updates
                if (transaction?.status === 'MFA_REQUIRED') {
                    await addLogEvent(OKTA_LOGIN_URL + 'factors/' + factor?.id + '/verify', 'request', {
                        username,
                        deviceToken: deviceTokenFromLocalStorage || ''
                    });
                    transaction = await factor?.verify();
                    if (transaction?.status === 'FAILED' || transaction?.status === 'ERROR')
                        await addLogEvent(
                            OKTA_LOGIN_URL + 'factors/' + factor?.id + '/verify',
                            'response',
                            { username, deviceToken: deviceTokenFromLocalStorage || '', ...transaction },
                            'failed'
                        );
                    else
                        await addLogEvent(
                            OKTA_LOGIN_URL + 'factors/' + factor?.id + '/verify',
                            'response',
                            { username, deviceToken: deviceTokenFromLocalStorage || '' },
                            'success'
                        );
                }

                if (transaction?.status === 'MFA_ENROLL') {
                    transaction = await factor?.enroll();
                }
            }
            let user: any = {};
            if (transaction?.status === 'SUCCESS') {
                user = await getLoginUserInformation(dispatch, getState);
            }

            if (!user) {
                return rejectWithValue('User not allowed to access this application');
            }

            return { status: transaction?.status, user };
        } catch (error) {
            console.log(error);
            // unknow error object type
            await addLogEvent(
                OKTA_LOGIN_URL,
                'response',
                { username, deviceToken: deviceTokenFromLocalStorage || '', error },
                'failed'
            );
            return rejectWithValue(error);
        }
    }
);

export const resendMFA = createAsyncThunk('authState/resendMFA', async (_, { dispatch, rejectWithValue }) => {
    try {
        const resendMFATransaction = await transaction.resend('email');

        transaction.sessionToken = resendMFATransaction.sessionToken;
        return true;
    } catch (error) {
        console.log(error);
        dispatch(logEventToBackEnd('RESEND_MFA_API_ERROR'));
        return rejectWithValue(error);
    }
});

type ConfirmMFAAndLoginType = { oktaAuth: OktaAuth; code: string; rememberDevice?: boolean; username: string };

let mfaTransaction = null;

export const confirmMFAAndLogin = createAsyncThunk(
    'authState/confirmMFA',
    async ({ code, rememberDevice, username }: ConfirmMFAAndLoginType, { rejectWithValue, dispatch, getState }) => {
        try {
            await addLogEvent(
                OKTA_LOGIN_URL + 'factors/' + factor?.id + '/verify?rememberDevice=' + rememberDevice,
                'request',
                { username, deviceToken: deviceTokenFromLocalStorage || '' }
            );
            // const state: any = getState();
            // const tenantId = state?.global?.globalConfig?.subdomain;

            if (transaction.status === 'MFA_ENROLL_ACTIVATE') {
                mfaTransaction = await transaction.activate({
                    passCode: code,
                    rememberDevice
                });
            } else {
                mfaTransaction = await transaction.verify({
                    passCode: code,
                    rememberDevice
                });
            }

            // Reset device token if remember device is not selected
            if (!rememberDevice) {
                localStorage.removeItem(OKTA_DEVICE_TOKEN_KEY);

                const uuid = uuidv4();

                localStorage.setItem(OKTA_DEVICE_TOKEN_KEY, uuid.substring(0, 32));
            }

            transaction.sessionToken = mfaTransaction.sessionToken;

            // log to backend
            if (transaction?.status === 'FAILED' || transaction?.status === 'ERROR')
                await addLogEvent(
                    OKTA_LOGIN_URL + 'factors/' + factor?.id + '/verify?rememberDevice=' + rememberDevice,
                    'response',
                    { username, deviceToken: deviceTokenFromLocalStorage || '', ...transaction },
                    'failed'
                );
            else
                await addLogEvent(
                    OKTA_LOGIN_URL + 'factors/' + factor?.id + '/verify?rememberDevice=' + rememberDevice,
                    'response',
                    { username, deviceToken: deviceTokenFromLocalStorage || '' },
                    'success'
                );

            const user: any = await getLoginUserInformation(dispatch, getState);

            if (!user) {
                return rejectWithValue('User not allowed to access this application');
            }

            return { status: mfaTransaction.status, user };
        } catch (error) {
            console.log(error);
            await addLogEvent(
                OKTA_LOGIN_URL + 'factors/' + factor?.id + '/verify?rememberDevice=' + rememberDevice,
                'response',
                { username, deviceToken: deviceTokenFromLocalStorage || '', error },
                'failed'
            );
            dispatch(logEventToBackEnd('CONFIRM_MFA_API_ERROR'));
            return rejectWithValue(error);
        }
    }
);

export const forgotPassword = async (email: string) => {
    try {
        const response = await uipApiInstance({
            method: 'POST',
            url: '/api/advice/forgotpassword',
            withCredentials: false,
            data: {
                email
            }
        });
        return response?.data?.success || response;
    } catch (error) {
        console.log(error);
        return error;
    }
};

export const sendForgotPasswordLink = createAsyncThunk(
    'authState/sendForgotPasswordLink',
    async ({ email }: any, { rejectWithValue, dispatch }) => {
        try {
            const response = await uipApiInstance({
                method: 'POST',
                url: '/forgotpassword',
                withCredentials: false,
                data: {
                    email
                }
            });

            return response.data?.success;
        } catch (error) {
            console.log(error);
            dispatch(logEventToBackEnd('FORGOT_PASSWORD_API_ERROR'));
            return rejectWithValue(error);
        }
    }
);

export const logout = createAction('authState/logout');

const authSlice = createSlice({
    name: 'authState',
    initialState,
    reducers: {
        toggleSessionExpiredModal: (state, action: PayloadAction<any>) => {
            state.showSessionExpiredModal = action.payload;
        },
        updateOktaAuthState: (state, action: PayloadAction<any>) => {
            state.isAuthenticated = true;
            state.oktaState = action.payload;
        },
        resetAuthReducer: () => initialState
    },
    extraReducers: (builder) => {
        builder.addCase(logout, (state) => ({
            ...initialState,
            showSessionExpiredModal: state.showSessionExpiredModal
        })),
            builder.addCase(getLoggedInUserInfo.pending, (state) => {
                state.loading = true;
            }),
            builder.addCase(getLoggedInUserInfo.fulfilled, (state, action) => {
                state.loading = false;
                state.user = action.payload;
                state.error = '';
            }),
            builder.addCase(getLoggedInUserInfo.rejected, (state, action) => {
                state.loading = false;
                state.user = {};
                state.error = action.error;
            }),
            builder.addCase(updateHantzUserSettings.pending, (state) => {
                state.isHantzUserSettings = false;
            }),
            builder.addCase(updateHantzUserSettings.fulfilled, (state) => {
                state.isHantzUserSettings = true;
            }),
            builder.addCase(updateHantzUserSettings.rejected, (state, { error }) => {
                state.isHantzUserSettings = false;
                state.error = error;
            });

        /** TODO:: DELETE THESE BLOCKS STARTS*/

        // builder.addCase(authenticateUser.pending, (state) => {
        //     state.loading = true;
        // }),
        // builder.addCase(authenticateUser.fulfilled, (state, action) => {
        //     state.loading = false;
        //     state.isAuthenticated = Boolean(action.payload.user && Object.keys(action.payload.user).length);
        //     state.user = action.payload.user;
        //     state.error = '';
        // }),
        //     builder.addCase(authenticateUser.rejected, (state, action) => {
        //         state.loading = false;
        //         state.user = {};
        //         state.error = action.error;
        //     });
        // builder.addCase(resendMFA.pending, (state) => {
        //     state.isSendingMFA = true;
        // }),
        //     builder.addCase(resendMFA.fulfilled, (state) => {
        //         state.isSendingMFA = false;
        //         state.error = '';
        //     }),
        //     builder.addCase(resendMFA.rejected, (state, action) => {
        //         state.isSendingMFA = false;
        //         state.error = action.payload;
        //     });
        // builder.addCase(confirmMFAAndLogin.pending, (state) => {
        //     state.loading = true;
        // }),
        // builder.addCase(confirmMFAAndLogin.fulfilled, (state, action) => {
        //     state.loading = false;
        //     state.isAuthenticated = Boolean(action.payload.user && Object.keys(action.payload.user).length);
        //     state.user = action.payload.user;
        //     state.error = '';
        // }),
        // builder.addCase(confirmMFAAndLogin.rejected, (state, action) => {
        //     state.loading = false;
        //     state.isAuthenticated = false;
        //     state.user = {};
        //     state.error = action.error;
        // });
        // builder.addCase(sendForgotPasswordLink.pending, (state) => {
        //     state.sendingForgotPassword = true;
        // }),
        // builder.addCase(sendForgotPasswordLink.fulfilled, (state) => {
        //     state.sendingForgotPassword = false;
        //     state.error = '';
        // }),
        // builder.addCase(sendForgotPasswordLink.rejected, (state, action) => {
        //     state.sendingForgotPassword = false;
        //     state.error = action.error;
        // });

        /** DELETE THESE BLOCKS ENDS*/

        /** SHOW SESSION EXPIRED MODAL */
        // builder.addCase(logout, (state) => ({
        //     ...initialState,
        //     showSessionExpiredModal: state.showSessionExpiredModal
        // })),
    }
});

export const { toggleSessionExpiredModal, resetAuthReducer, updateOktaAuthState } = authSlice.actions;

export default authSlice.reducer;
