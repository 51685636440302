import { useOktaAuth } from '@okta/okta-react';
import { useEffect } from 'react';
import { useAppDispatch } from '../common/hooks';
import { updateOktaAuthState } from '../features/auth/authSlice';

export const AuthProvider = () => {
    const okta = useOktaAuth();
    const dispatch = useAppDispatch();
    const authState = okta?.authState;
    const isAuthenticated = okta?.authState?.isAuthenticated;

    useEffect(() => {
        // Update updateOktaAuthState in auth state
        if (isAuthenticated) {
            dispatch(updateOktaAuthState(authState));
        }
    }, [isAuthenticated]);
    return <> </>;
};
