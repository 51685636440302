import { useLayoutEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Hantz } from '../../Hantz';
import { useAppDispatch, useAppSelector } from '../../common/hooks';
import { Spinner } from '../../components/atoms/Spinner/Spinner';
import { AdvisorConsole } from '../../components/organisms/AdvisorConsole';
import { EmptyClients } from '../../components/organisms/EmptyClients';
import { advisorConsoleActions, getAdvisorConsoleProposals } from '../../features/advisorConsole';
import { initializeAdviceClient } from '../../features/client';
import { initializeGoals } from '../../features/client-goals';

const Clients = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const accessToken = useAppSelector((state) => state?.auth?.oktaState?.accessToken?.accessToken);
    const tenantsList = useAppSelector((state) => state?.auth?.user?.tenants);
    const userRole = useAppSelector((state) => state?.auth?.user?.userRole);

    const { tenant, advisorConsole } = useAppSelector((state) => ({
        tenant: state?.global?.globalConfig?.subdomain,
        advisorConsole: state?.advisorConsole
    }));

    useLayoutEffect(() => {
        dispatch(getAdvisorConsoleProposals({ tenant }));
    }, [tenant, dispatch]);

    const { loading = false, items = [], pagination, filters, hasFilters, clients = [] } = advisorConsole || {};

    if (loading || userRole === undefined || tenantsList === undefined) {
        return <Spinner enabled />;
    }

    if (!items?.length && !hasFilters && userRole !== 'advisor' && !tenantsList?.includes('hantz')) {
        return <EmptyClients />;
    }

    const scrollOnChange = (changeAction: () => void) => {
        window.scrollTo({ top: 0, behavior: 'smooth' });

        return changeAction();
    };

    const navigateToCreateClient = () => {
        // initialize page upon landing on /createclient
        dispatch(initializeAdviceClient());
        dispatch(initializeGoals());
        navigate('/createclient');
    };

    if (userRole === 'advisor' && tenantsList?.includes('hantz')) {
        return <Hantz accessToken={accessToken} />;
    } else {
        if (!items?.length && !hasFilters) {
            return <EmptyClients />;
        } else
            return (
                <AdvisorConsole
                    clients={clients}
                    items={items}
                    pagination={pagination}
                    onChangePage={(page) => scrollOnChange(() => dispatch(advisorConsoleActions.changePage(page)))}
                    onChangeItemsPerPage={(itemsPerPage) =>
                        scrollOnChange(() => dispatch(advisorConsoleActions.changeItemsPerPage(itemsPerPage)))
                    }
                    filters={filters}
                    onChangeFilters={(filters) => dispatch(advisorConsoleActions.setFilters(filters))}
                    navigateToCreateClient={navigateToCreateClient}
                />
            );
    }
};

export default Clients;
