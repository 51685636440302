import { Grid, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { CLIENT_PROFILE_STAGES, iwsGoalTypeMapper } from '../../common/constants';
import { useAppDispatch, useAppSelector } from '../../common/hooks';
import { BasicButton, Typography } from '../../components/atoms';
import { Stepper, StepperProps } from '../../components/molecules';
import { updateClient, updateCurrentStep } from '../../features/client';
import { removeLoader, showLoader, updateBanner, updateGoal } from '../../features/client-goals';
import { updateGlobalLoaderState } from '../../features/global/globalSlice';
import { runpipe } from '../../services/clients/runpipe';
import { CreateClientStep } from './CreateClientStep';
import { CreateGoalsStep } from './CreateGoalsStep';
import { ReviewPlanDetailsStep } from './ReviewPlanDetailsStep';
import { cloneDeep } from 'lodash';
import { goalcalculator } from '../../services/clients/goalcalculator';
import { useState } from 'react';
import { Banner } from '../../components/molecules/Banner/Banner';
import { useNavigate } from 'react-router-dom';
import PDFViewer from '../../components/organisms/PDF/PDFViewer';
import GeneratedPlanPDF from '../../components/organisms/PDF/GeneratedPDFs/GeneratedPlanPDF';
import { isStep1Completed } from '../../common/helper';
import dayjs from 'dayjs';
import { StickyRegion } from '../../containers/sticky-region';

interface ClientProfileProps {
    // eslint-disable-next-line no-unused-vars
    updateStep?: (step: number | ((prev: number) => number)) => void;
    disabled?: boolean;
}

const ClientJourney = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const adviceClient = useAppSelector((state) => state.adviceClient);
    const adviceGoals = useAppSelector((state) => state.adviceGoals);
    const global = useAppSelector((state) => state.global);
    const [openPDFModal, setOpenPDFModal] = useState(false);
    const navigate = useNavigate();

    const banner = adviceGoals?.global?.banner;
    const isPlanRun = adviceGoals?.global?.isPlanRun;
    const bannerChangesExist = banner?.visible && banner.type === 'attention';
    const shouldRunPipe = adviceGoals.response?.length === 0 || bannerChangesExist;
    const { clientFirstName, clientLastName } = adviceClient;
    const clientFullName = `${clientFirstName} ${clientLastName}`;

    const HEADERS = {
        clientemail: global?.orgInfo?.orgEmail,
        version: 4
    };
    //required for future purpose - toast

    // const errorHandler = (err: any) => {
    //     dispatch(updateErrorModalState({ showErrorModal: true, error: { message: err?.response?.data?.message || err?.message, heading: 'Runpipe Error' }}));
    // }

    const calculateProbabilityOfSuccessForProposal = () => {
        const clonedAdviceGoals = cloneDeep(adviceGoals);
        let probabilityOfSuccessArray: number[] = [];
        const modifiedAdviceGoalsFormFields = clonedAdviceGoals.formFields.map((item: any) => {
            const goalJson = clonedAdviceGoals?.response?.find(
                (itemResponse: any) => item.goalId == itemResponse?.goalId
            );
            const analysisReport = goalJson?.['response']?.['body']?.['analysisReport'];

            const portfolio = global.portfolio?.filter(
                (_sPort) => _sPort.portfolioId === analysisReport?.['recommendedPortfolioId']
            );

            const groupedPortfolio = portfolio?.[0]?.assets?.map((_type: any) => ({
                type: _type.type,
                totalAllocatedvalue: `${_type.totalAllocatedvalue}%`
            }));
            // get equity fixed income
            const equityFixedIncomeCash = groupedPortfolio?.reduce(
                (
                    acc: { equity: number; fixedIncome: number; cash: number },
                    item: { type: string; totalAllocatedvalue: string }
                ) => {
                    if (item.type === 'Equity') {
                        acc.equity += parseFloat(item.totalAllocatedvalue);
                    } else if (item.type === 'Fixed Income') {
                        acc.fixedIncome += parseFloat(item.totalAllocatedvalue);
                    } else if (item.type === 'Cash') {
                        acc.cash += parseFloat(item.totalAllocatedvalue);
                    }
                    return acc;
                },
                { equity: 0, fixedIncome: 0, cash: 0 }
            );
            const equityFixedIncomeString = `${Math.round(equityFixedIncomeCash?.equity)} / ${Math.round(
                equityFixedIncomeCash?.fixedIncome
            )} / ${Math.round(equityFixedIncomeCash?.cash)}`;

            // get probability of success
            const probabilityOfSuccess = Number(analysisReport?.['currentGoalProbability'] * 100);
            item.data.probabilityOfSuccess = probabilityOfSuccess;
            item.data.equityFixedIncome = equityFixedIncomeString;
            probabilityOfSuccessArray.push(probabilityOfSuccess);
            return item;
        });
        return { modifiedAdviceGoalsFormFields, probabilityOfSuccessArray };
    };

    const handleSavePlan = async () => {
        const { modifiedAdviceGoalsFormFields, probabilityOfSuccessArray } = calculateProbabilityOfSuccessForProposal();
        dispatch(showLoader());
        const timestamp = dayjs().format('YYYY-MM-DD HH:mm:ss.SSSSSS');
        const canEnablePDFGenerate = adviceGoals.global.banner?.type !== 'attention';
        await dispatch(
            updateGoal({
                ...adviceGoals,
                formFields: modifiedAdviceGoalsFormFields,
                global: {
                    ...adviceGoals?.global,
                    status: 'complete',
                    ...(!adviceGoals.global.planCreationDate && { planCreationDate: timestamp }),
                    lastUpdateDate: timestamp,
                    enablePDFGenerate: canEnablePDFGenerate,
                    probabilityOfSuccess: Math.min(...probabilityOfSuccessArray)                }
            })
        );
        dispatch(removeLoader());
        navigate('/clients');
    };

    const generateAccountsArray = (
        goalId: string,
        incomeSource: any,
        incomeSources: any,
        investmentSource: any,
        investmentsSources: any
    ) => {
        let accountsArray = [
            ...incomeSource?.flatMap((account: any) => {
                const accountDetails: any = incomeSources?.find((ac: any) => ac.accountId === account.accountNumber);
                if (!accountDetails) {
                    return []; // might have been deleted
                }

                return {
                    fundingType: 'incomeSources',
                    recurringContribution: Number(accountDetails?.amount) || 0,
                    escalationPercentage: Number(accountDetails?.escalateIncome) || 0,
                    escalationYears: 1,
                    frequency: accountDetails?.frequency === 'Monthly' ? 'monthly' : 'yearly',
                    startDate: accountDetails?.beginningYear, // since only beginningYear is captured from UI
                    endDate: accountDetails?.endYear
                        ? accountDetails?.endYear
                        : Number(accountDetails?.beginningYear) + 5 // if end year is not given by user, using beginningYear + 5
                };
            })
        ];
        accountsArray = [
            ...accountsArray,
            ...investmentSource?.map((account: any) => {
                const accountDetails: any = investmentsSources?.find(
                    (ac: any) => ac.accountId === account.accountNumber
                );
                return {
                    fundingType: 'investmentAccounts',
                    amount: accountDetails?.amount,
                    recurringContribution: Number(accountDetails?.recurringContributions) || 0,
                    escalationPercentage: Number(accountDetails?.escalationContributions) || 0,
                    escalationYears: 1,
                    startDate: accountDetails?.recurringContributionsStartDate,
                    endDate: accountDetails?.recurringContributionsEndDate,
                    frequency: accountDetails?.frequency === 'Monthly' ? 'monthly' : 'yearly',
                    ...(account?.isRCSelected && { includeRecurringContributionsForGoal: goalId })
                };
            })
        ];
        return accountsArray;
    };
    // disable IWS AEX-1611
    // const prepareIWSData = () => {
    //     //payload for wealth goals
    //     return {
    //         riskProfile: adviceClient?.riskProfile,
    //         goals: adviceGoals?.formFields?.map((goal: any) => {
    //             return {
    //                 goalId: goal?.goalId,
    //                 goalType: iwsGoalTypeMapper[goal?.data?.goalType],
    //                 goalName: goal?.data?.goalName || '',
    //                 riskProfile: adviceClient?.riskProfile,
    //                 targetDate: goal?.data?.goalDate || goal?.data?.targetDate,
    //                 goalAmount: goal?.data?.goalAmount,
    //                 targetedRetirementIncome: goal?.data?.targetedRetirementIncome,
    //                 planStartRetirement: goal?.data?.planStartRetirement,
    //                 goalPriority: goal?.data?.goalPriority,
    //                 accounts: generateAccountsArray(
    //                     goal?.goalId,
    //                     goal?.data?.sources?.incomeSource,
    //                     adviceClient?.accounts?.incomeSources,
    //                     goal?.data?.sources?.investmentSource,
    //                     adviceClient?.accounts?.investmentsSources
    //                 )
    //             };
    //         })
    //     };
    // };

    const prepareRunpipeData = (goal: any) => ({
        isIWSApplied: false,
        goalId: goal.goalId,
        goalType: iwsGoalTypeMapper[goal?.data?.goalType],
        goalName: goal?.data?.goalName || '',
        riskProfile: adviceClient?.riskProfile,
        targetDate: goal?.data?.goalDate || goal?.data?.targetDate,
        ...(goal?.data?.goalAmount !== undefined && { goalAmount: goal.data.goalAmount }),
        goalPriority: goal?.data?.goalPriority,
        ...(goal?.data?.planStartRetirement && {
            planStartRetirement: goal.data.planStartRetirement
        }),
        ...(goal?.data?.targetedRetirementIncome !== undefined && {
            targetedRetirementIncome: goal.data.targetedRetirementIncome
        }),
        accounts: generateAccountsArray(
            goal?.goalId,
            goal?.data?.sources?.incomeSource,
            adviceClient?.accounts?.incomeSources,
            goal?.data?.sources?.investmentSource,
            adviceClient?.accounts?.investmentsSources
        )
    });

    const fetchIWSandRunpipe = async () => {
        // IWS api call
        // const iwsResponse = !onlyRunpipe ? await iws(prepareIWSData(), HEADERS) : { goalResponseList: [] };
        let requestArrayForRedux: any = [];
        let requestArrayForReduxGoalAmt: any = [];
        let responseArrayForRedux: any = [];
        let responseArrayForReduxGoalAmt: any = [];
        let keyData: any = [];
        let newArr: any = [];
        let newArrGoalAmt: any = [];
        let recommendationArr: any = [];
        // parallel runpipe and goal calculator api calls for each goal
        adviceGoals?.formFields?.map(async (goal: any) => {
            const runpipeRequest = prepareRunpipeData(goal);
            const runpipeRequestGoalAmt = cloneDeep(runpipeRequest);
            if (runpipeRequestGoalAmt.goalType !== 'retirement') runpipeRequestGoalAmt['goalAmount'] = 0;

            requestArrayForRedux.push(runpipeRequest);
            requestArrayForReduxGoalAmt.push(runpipeRequestGoalAmt);

            const newPrRunpipe = Promise.all([runpipe(runpipeRequest, HEADERS)]);
            responseArrayForRedux.push(newPrRunpipe);
            const newPrGoalAmt = Promise.all([goalcalculator(runpipeRequestGoalAmt, HEADERS)]);
            responseArrayForReduxGoalAmt.push(newPrGoalAmt);
            keyData.push(goal.goalId);
            recommendationArr.push({
                goalId: runpipeRequest.goalId,
                orginalAmount: runpipeRequest.goalAmount,
                oneTimeTopUp: 0,
                topUpAccumulation: 0,
                toUpDeccumulation: 0,
                recomendedTenure: 0
            });
        });

        const resPr = await Promise.all(responseArrayForRedux);
        // eslint-disable-next-line no-unused-vars
        resPr.forEach((_, index) => {
            if (resPr[index][0]) {
                resPr[index][0]['goalId'] = keyData[index];
                newArr.push(resPr[index][0]);
            }
        });
        const resPrGoalAmt = await Promise.all(responseArrayForReduxGoalAmt);
        // eslint-disable-next-line no-unused-vars
        resPrGoalAmt.forEach((_, index) => {
            if (resPrGoalAmt[index][0]) {
                resPrGoalAmt[index][0]['goalId'] = keyData[index];
                newArrGoalAmt.push(resPrGoalAmt[index][0]);
            }
        });
        await dispatch(
            updateGoal({
                ...adviceGoals,
                request: requestArrayForRedux,
                response: newArr,
                recommendation: recommendationArr,
                global: {
                    ...adviceGoals?.global,
                    hasRunPipe: true,
                    ...(adviceGoals?.global?.banner?.visible && {
                        banner: { ...adviceGoals?.global?.banner, type: 'success', message: '' }
                    }),
                    responseGoalCalculator: newArrGoalAmt,
                    isPlanRun: true                }
            })
        );
    };

    const prepareForReviewPlanStep = async () => {
        // add other clean up activities before review plan step
        await fetchIWSandRunpipe();
    };

    //styled elements
    const JourneyContainer = styled('main')(() => ({
        display: 'flex',
        flexDirection: 'row',
        padding: '10px 40px 10px 40px',
        flexGrow: 1
        // marginBottom: '80px'
        // maxWidth: theme.breakpoints.values.lg // let's not add a maxWidth until we have a solution for the LeftPanel position
    }));

    const ContinueToNextStep = ({ updateStep, disabled }: ClientProfileProps) => (
        <BasicButton
            variant="contained"
            onClick={async () => {
                updateStep?.((prev) => prev + 1);
                // update the step in redux
                await dispatch(updateCurrentStep(adviceClient.step + 1));
                await dispatch(updateClient({ ...adviceClient, step: adviceClient.step + 1 }));
            }}
            disabled={disabled}
        >
            {t('CLIENT_ONBOARDING_CONTD_BTN')}
        </BasicButton>
    );

    const ContinueToReviewStep = ({ updateStep, disabled }: ClientProfileProps) => (
        <BasicButton
            variant="contained"
            onClick={async () => {
                if (shouldRunPipe) {
                    dispatch(updateGlobalLoaderState(true));
                    await prepareForReviewPlanStep();
                    dispatch(updateGlobalLoaderState(false));
                }
                updateStep?.((prev) => prev + 1);
                // update the step in redux
                await dispatch(updateCurrentStep(adviceClient.step + 1));
            }}
            disabled={disabled}
        >
            {t('CLIENT_ONBOARDING_CONTD_BTN')}
        </BasicButton>
    );

    // Initializing Stepper steps
    const CreateProfile = ({ updateStep }: ClientProfileProps) => (
        <>
            <StickyRegion>
                <StyledBanner>
                    {banner && banner.visible && banner.type != 'success' && adviceGoals?.formFields?.length > 0 && (
                        <Banner
                            type={banner.type || 'attention'}
                            handleClose={() => {
                                dispatch(updateBanner({ visible: false }));
                            }}
                            handleTryAgain={async () => {
                                updateStep?.(1);
                                await dispatch(updateCurrentStep(1));
                            }}
                            isGoalSummary={false}
                            message={banner.message}
                        />
                    )}
                </StyledBanner>
                <Grid container justifyContent="space-between" alignItems="center" padding="16px 0">
                    <Typography variant="headers.h2" color="neutral.grey.500" t="CLIENT_ONBOARDING_STEP1"></Typography>
                    <ContinueToNextStep
                        updateStep={updateStep}
                        disabled={!isStep1Completed(CLIENT_PROFILE_STAGES, adviceClient)}
                    />
                </Grid>
            </StickyRegion>
            <CreateClientStep />
        </>
    );

    const StyledBanner = styled('div')(() => ({
        marginBottom: '8px',
        marginTop: '16px'
    }));

    const CreateGoals = ({ updateStep }: ClientProfileProps) => (
        <>
            <StickyRegion>
                {isPlanRun &&
                    banner &&
                    banner.visible &&
                    banner.type != 'success' &&
                    adviceGoals?.formFields?.length > 0 && (
                        <StyledBanner>
                            <Banner
                                type={banner.type || 'attention'}
                                handleClose={() => {
                                    dispatch(updateBanner({ visible: false }));
                                }}
                                handleTryAgain={async () => {
                                    if (shouldRunPipe) {
                                        dispatch(showLoader());
                                        await prepareForReviewPlanStep();
                                        dispatch(removeLoader());
                                    }
                                    updateStep?.((prev) => prev + 1);
                                    await dispatch(updateCurrentStep(adviceClient.step + 1));
                                }}
                                isGoalSummary={banner.isGoalSummary}
                                message={banner.message}
                            />
                        </StyledBanner>
                    )}
                <Grid container justifyContent="space-between" padding="16px 0" alignItems="center">
                    <Typography variant="headers.h2" color="neutral.grey.500" t="CLIENT_ONBOARDING_STEP2"></Typography>
                    <ContinueToReviewStep
                        updateStep={updateStep}
                        disabled={!adviceGoals?.formFields || adviceGoals?.formFields?.length < 1}
                    />
                </Grid>
            </StickyRegion>
            <CreateGoalsStep />
        </>
    );

    const ReviewPlanDetails = ({ updateStep }: ClientProfileProps) => {
        const disablePDFGenerate = !adviceGoals.global?.enablePDFGenerate;
        return (
            <div style={{ maxWidth: '100%' }}>
                <StickyRegion>
                    {isPlanRun && banner && banner.visible && (
                        <StyledBanner>
                            <Banner
                                type={banner.type || 'attention'}
                                handleClose={() => {
                                    dispatch(updateBanner({ visible: false }));
                                }}
                                message={banner.message}
                                handleTryAgain={
                                    banner.isGoalSummary
                                        ? async () => {
                                              try {
                                                  if (shouldRunPipe) {
                                                      dispatch(showLoader());
                                                      await prepareForReviewPlanStep();
                                                      dispatch(removeLoader());
                                                  }
                                              } catch {
                                                  dispatch(removeLoader());
                                              }
                                          }
                                        : async () => {
                                              updateStep?.(1);
                                              // update the step in redux
                                              await dispatch(updateCurrentStep(1));
                                              dispatch(
                                                  updateBanner({
                                                      ...banner,
                                                      type: 'attention',
                                                      isGoalSummary: true,
                                                      message: ''
                                                  })
                                              );
                                          }
                                }
                                isGoalSummary={banner.isGoalSummary}
                            />
                        </StyledBanner>
                    )}
                    <Grid container justifyContent="space-between" padding="16px 0" alignItems="center">
                        <Typography
                            variant="headers.h2"
                            color="neutral.grey.500"
                            t="CLIENT_ONBOARDING_STEP4"
                        ></Typography>
                        <Grid>
                            <BasicButton
                                variant="outlined"
                                disabled={disablePDFGenerate}
                                onClick={() => setOpenPDFModal(true)}
                                loading={openPDFModal}
                            >
                                {t('CLIENT_ONBOARDING_Share_PDF')}
                            </BasicButton>
                            <BasicButton variant="contained" sx={{ marginLeft: '10px' }} onClick={handleSavePlan}>
                                {t('CLIENT_ONBOARDING_STEP5')}
                            </BasicButton>
                        </Grid>
                    </Grid>
                </StickyRegion>
                <ReviewPlanDetailsStep updateStep={updateStep} />
            </div>
        );
    };

    const journeySteps: StepperProps = {
        steps: [
            { name: t('CLIENT_ONBOARDING_STEP1'), content: <CreateProfile /> },
            { name: t('CLIENT_ONBOARDING_STEP2'), content: <CreateGoals /> },
            { name: t('CLIENT_ONBOARDING_STEP4'), content: <ReviewPlanDetails /> }
        ],
        initialStepIndex: adviceClient.step,
        runPlan: prepareForReviewPlanStep
    };

    const generatePDFfilename = () =>
        `${clientFullName?.trim()} ${dayjs(new Date()).format('DD/MM/YYYY').replaceAll('/', '')}`;

    return (
        <>
            {
                <PDFViewer
                    open={openPDFModal}
                    handleClose={() => setOpenPDFModal(false)}
                    filename={generatePDFfilename()}
                >
                    <GeneratedPlanPDF />
                </PDFViewer>
            }
            <JourneyContainer>
                <Stepper {...journeySteps} />
            </JourneyContainer>
        </>
    );
};
export default ClientJourney;
