/* eslint-disable operator-linebreak */
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { toRelativeUrl } from '@okta/okta-auth-js';
import { Security } from '@okta/okta-react';
import { isEmpty, merge } from 'lodash';
import { ErrorInfo, memo, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from './common/hooks';
import { Header } from './components/molecules/Header/Header';
import ErrorBoundaryComponent from './components/organisms/ErrorBoundary';
import { breakpoints, palette, typography } from './themes';
// import ErrorPopup from './components/molecules/error-modal';
import AppRoutes from './routes';
// import EulaContainer from './containers/eula';
import { AuthProvider } from '../src/auth';
import { allowedDomainNameOrigins } from './common/constants';
import { Spinner } from './components/atoms';
import { AlertDialog } from './components/molecules/AlertDialog/AlertDialog';
import Timeout from './components/molecules/Timeout';
import { getLoggedInUserInfo } from './features/auth/authSlice';
import { syncAdviceClient } from './features/client';
import { syncAdviceGoals } from './features/client-goals';
import {
    fetchGoeConfig,
    fetchPortfolio,
    getTenantBasedConfig,
    logEventToBackEnd,
    updateGlobalLoaderState
} from './features/global/globalSlice';
import oktaAuth from './services/configs/oktaAuth';
import { overrides } from './themes/overrides';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';

const makeUseMemoRun = () => `#${Math.floor(Math.random() * 16777215).toString(16)}`;

function App() {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    // const [showErrorPopup, setShowErrorPopup] = useState(false);
    const [updatedPalette, setUpdatedPalette] = useState(palette);
    const [showExternalLinkModal, setShowExternalLinkModal] = useState('');
    const [isChanged, setIsChanged] = useState(makeUseMemoRun);
    const { i18n, t } = useTranslation();

    const isAuthenticated = useAppSelector((state) => state.auth.isAuthenticated);
    const tenantsList = useAppSelector((state) => state?.auth?.user?.tenants);

    const { multiTenancyRefresh, globalLoader } = useAppSelector((state) => ({
        ...state.global
    }));

    const globalConfig = useAppSelector((state) => state?.global.globalConfig);
    const adviceClient = useAppSelector((state) => state?.adviceClient);
    const adviceGoals = useAppSelector((state) => state?.adviceGoals);
    const portfolio = useAppSelector((state) => state?.global.portfolio);
    const goeConfig = useAppSelector((state) => state?.global.goeConfig);

    useLayoutEffect(() => {
        if (isEmpty(globalConfig?.styles)) {
            dispatch(updateGlobalLoaderState(true));
        }
    }, []);

    useLayoutEffect(() => {
        // Get market portfolios
        if (isEmpty(portfolio)) {
            dispatch(fetchPortfolio());
        }
    }, [tenantsList]);

    useEffect(() => {
        // Get logged in user info
        if (isAuthenticated) {
            dispatch(getLoggedInUserInfo());
        }
    }, [isAuthenticated]);

    useEffect(() => {
        // Get Goe Config
        if (isEmpty(goeConfig)) {
            dispatch(fetchGoeConfig());
        }
    }, [tenantsList]);

    useEffect(() => {
        // Get tenant info for current DNS: document?.location?.hostname
        dispatch(getTenantBasedConfig({ tenantId: document?.location?.hostname, i18n }));

        const handleExternalLinkClicked = (event: any) => {
            if (
                event.target.tagName === 'A' &&
                event.target.getAttribute('target') === '_blank' &&
                new URL(event.target.href).origin !== window.location.origin &&
                !allowedDomainNameOrigins.includes(new URL(event.target.href).origin)
            ) {
                event.preventDefault();
                setShowExternalLinkModal(event.target.href);
            }
        };
        window.addEventListener('click', handleExternalLinkClicked);
        return () => window.removeEventListener('click', handleExternalLinkClicked);
    }, []);

    useEffect(() => {
        // Update subdomain tenant to adviceClient and adviceGoals in redux store so that
        // all clients & goals related api calls automatically use current tenant
        dispatch(syncAdviceClient({ ...adviceClient, tenant: globalConfig?.subdomain || 'default' }));
        dispatch(syncAdviceGoals({ ...adviceGoals, tenant: globalConfig?.subdomain || 'default' }));
    }, [globalConfig?.subdomain]);

    useEffect(() => {
        if (globalConfig?.styles) {
            const updated = merge(updatedPalette, globalConfig?.styles?.config);
            setUpdatedPalette(updated);
            setIsChanged(makeUseMemoRun);
        }
    }, [globalConfig?.styles?.config, multiTenancyRefresh]);

    const memoizedThemeValue = useMemo(
        () => ({
            palette: updatedPalette,
            typography,
            breakpoints,
            shape: { borderRadius: 2 }
        }),
        [isChanged, updatedPalette]
    );

    const theme = createTheme(memoizedThemeValue);
    theme.components = overrides(theme);

    // useEffect(() => {
    //     if (!isEmpty(error) && showErrorModal) {
    //         setShowErrorPopup(showErrorModal);
    //     } else {
    //         setShowErrorPopup(showErrorModal as any);
    //     }
    // }, [error, showErrorModal]);

    const customAuthHandler = () => {
        navigate('/');
    };

    const restoreOriginalUri = async (_oktaAuth: any, originalUri: string) => {
        navigate(toRelativeUrl(originalUri || '', window.location.origin), { replace: true });
    };

    const cache = createCache({
        key: 'mui',
        nonce: 'obZEAN1Cu1gvwI6fM2WxRQ==',
        prepend: true
    });

    return (
        <CacheProvider value={cache}>
            <ThemeProvider theme={theme}>
                <ErrorBoundary
                    FallbackComponent={ErrorBoundaryComponent}
                    onError={(error: Error, info: ErrorInfo) => {
                        console.log(error, info);
                        dispatch(logEventToBackEnd('UI_CRASH'));
                    }}
                >
                    <Security
                        oktaAuth={oktaAuth}
                        onAuthRequired={customAuthHandler}
                        restoreOriginalUri={restoreOriginalUri}
                    >

                        <Spinner enabled={globalLoader || adviceClient?.loading || adviceGoals?.loading} />
                        <CssBaseline />
                        <AuthProvider />
                        <AppRoutes />
                        {/* <ErrorPopup open={showErrorPopup} loading={loading} /> <EulaContainer />  */}
                        {isAuthenticated && <Timeout />}
                        {Boolean(showExternalLinkModal) && (
                            <AlertDialog
                                handleClose={() => setShowExternalLinkModal('')}
                                onCancellation={() => setShowExternalLinkModal('')}
                                open={Boolean(showExternalLinkModal)}
                                type="confirmation"
                                confirmButtonLabel="OK"
                                content={t('EXTERNAL_LINK_MODAL_CONTENT')}
                                onConfirmation={() => {
                                    window.open(showExternalLinkModal);
                                    setShowExternalLinkModal('');
                                }}
                                title={t('EXTERNAL_LINK_MODAL_TITLE')}
                                aria-label="close-session-expired-popup"
                                data-testid="session-expired-modal-close-button"
                            />
                        )}
                    </Security>
                </ErrorBoundary>
            </ThemeProvider>
        </CacheProvider>
    );
}

export default memo(App);
