import styled from '@emotion/styled';
import { BasicButton } from '../../atoms/BasicButton/BasicButton';
import { Pagination } from '../../atoms/Pagination';
import { AdminFilters } from '../../molecules/AdminFilters';
import { AdminTable, AdminTableItem } from '../../molecules/AdminTable';
import { useTranslation } from 'react-i18next';
import { AdvisorConsoleStore, AdvisorConsoleStoreFilters } from 'features/advisorConsole/types';
import { OptionType } from '../../molecules/AdminFilters/Dropdown';
import { Typography } from '../../atoms';
import { StickyRegion } from '../../../containers/sticky-region';

type Props = {
    items: AdminTableItem[];
    pagination: AdvisorConsoleStore['pagination'];
    // eslint-disable-next-line
    onChangePage(page: number): void;
    navigateToCreateClient(): void;
    // eslint-disable-next-line
    onChangeItemsPerPage(itemsPerPage: number): void;
    // eslint-disable-next-line
    onChangeFilters(filters: AdvisorConsoleStoreFilters): void;
    filters: AdvisorConsoleStoreFilters;
    clients: OptionType<string>[];
};

export const AdvisorConsole = ({
    items,
    pagination,
    onChangePage,
    onChangeItemsPerPage,
    filters,
    onChangeFilters,
    navigateToCreateClient,
    clients = []
}: Props) => {
    const { t } = useTranslation();

    return (
        <Container>
            <StickyRegion>
                <StyledHeader>
                    <Typography as="h1" variant="headers.h2" flex={1}>
                        Advisor Console
                    </Typography>
                    <BasicButton variant="contained" onClick={navigateToCreateClient}>
                        {t('TEXT_CREATE_A_NEW_PLAN')}
                    </BasicButton>
                </StyledHeader>
            </StickyRegion>
            <Card>
                <AdminFilters filters={filters} onChangeFilters={onChangeFilters} clients={clients} />
                <AdminTable items={items} />
            </Card>

            <PaginationContainer>
                <Pagination
                    onChangePage={onChangePage}
                    onChangeItemsPerPage={onChangeItemsPerPage}
                    totalPages={pagination.totalPages}
                    currentPage={pagination.page}
                    itemsPerPage={pagination.itemsPerPage}
                    totalItems={pagination.totalItems}
                />
            </PaginationContainer>
        </Container>
    );
};

const Container = styled('main')(() => ({
    padding: '60px 40px',
    width: '100%',
    display: 'flex',
    flexDirection: 'column'
}));

const StyledHeader = styled('header')(() => ({
    display: 'flex',
    alignItems: 'center',
    padding: '16px 0'
}));

const Card = styled('div')(() => ({
    borderRadius: '15px',
    border: '1px solid #E6E6E6',
    background: '#FFF',
    boxShadow: '0px 2px 8px 0px rgba(0, 0, 0, 0.05)',
    padding: '31px 24px',
    marginBottom: '24px'
}));

const PaginationContainer = styled('div')(() => ({
    display: 'flex',
    justifyContent: 'end'
}));
