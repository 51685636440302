import { styled } from '@mui/material';
import { toRelativeUrl } from '@okta/okta-auth-js';
import { useOktaAuth } from '@okta/okta-react';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../common/hooks';
import { Spinner } from '../components/atoms';
import { Footer, Header } from '../components/molecules';
import { ClickWrapAgreement } from '../components/molecules/ClickWrapAgreement/ClickWrapAgreement';
import { resetAdviceAdminReducer } from '../features/adviceAdmin';
import { advisorConsoleActions } from '../features/advisorConsole';
import { logout } from '../features/auth/authSlice';
import { initializeAdviceClient } from '../features/client';
import { initializeGoals } from '../features/client-goals';
import { logEventToBackEnd, saveUserAgreementsSettings } from '../features/global/globalSlice';
import { getCurrentYear } from '../utils';
import {
    calculateAnnualContribution,
    calculateTotalIncome,
    calculateTotalWealthAvailable
} from '../containers/funding-source-list/FundingSource';
import { removeLoader, showLoader, updateGoal } from '../features/client-goals';
import { LeftPanel } from '../components/molecules/LeftPanel/LeftPanel';
import AddIncomeSourcesDrawer from '../containers/add-income-sources-drawer';
import { AddInvestmentAccounts } from '../containers/add-investment-accounts';
import { RiskProfileDrawer } from '../containers/risk-tolerance-drawer';
import { PersonalInfoAndRelationshipsDrawer } from '../containers/create-client-step/PersonalInfoAndRelationshipsDrawer';

const allowedPathsForAdvisor = ['/clients', '/createclient', '/creategoals', '/reviewplan'];
const displaySidebarPaths = ['/creategoals', '/reviewplan'];

const PageContainer: any = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh'
}));

const StyledContainer: any = styled('div')(() => ({
    paddingTop: '60px',
    display: 'flex',
    flexGrow: 1
}));

const StyledMainContainer = styled('section')(() => ({
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column'
}));

const SideBarContainer = styled('aside')(() => ({
    position: 'sticky',
    top: '60px',
    height: 'calc(100vh - 60px)'
}));

export const RequiredAuth: React.FC = () => {
    const { authState, oktaAuth } = useOktaAuth();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const isAuthenticated = authState?.isAuthenticated;
    const isAuthLoading = useAppSelector((state) => state?.auth?.loading);
    const isAdvisorConsoleLoading = useAppSelector((state) => state?.advisorConsole?.loading);
    const user = useAppSelector((state) => state?.auth?.user);
    const global = useAppSelector((state) => state?.global);
    const { pathname } = useLocation();
    const [activeDrawer, setActiveDrawer] = useState<
        null | 'client' | 'relationship' | 'incomeSource' | 'wealthSource' | 'riskProfile'
    >(null);
    const adviceClient = useAppSelector((state) => state.adviceClient);
    const adviceGoals = useAppSelector((state) => state.adviceGoals);

    const {
        clientFirstName,
        clientLastName,
        relationship,
        riskProfile,
        accounts: { incomeSources, investmentsSources }
    } = adviceClient;
    const clientFullName = `${clientFirstName} ${clientLastName}`;
    const totalAnnualIncome = calculateTotalIncome(incomeSources);
    const totalWealthAvailable = calculateTotalWealthAvailable(investmentsSources);
    const totalAnnualContributions = calculateAnnualContribution(investmentsSources);
    const clientRelationship = `${relationship[0]?.firstName} ${relationship[0]?.lastName}`;
    const leftPanelData = {
        name: clientFullName,
        relationship: relationship.length > 0 ? clientRelationship : null,
        riskProfile: riskProfile,
        totalIncomeAvailable: totalAnnualIncome,
        totalRetirementAvailable: 253330000,
        annualRetirementContributions: 50000,
        totalWealthAvailable: totalWealthAvailable,
        annualWealthContributions: totalAnnualContributions,
        goalAmount: 20240
    };

    // TODO:: Delet this
    // const handleLogout = async () => {
    //     updateGlobalLoaderState(true);
    //     try {
    //         const sessionExists = await oktaAuth.session.exists();
    //         if (sessionExists) {
    //             await oktaAuth.closeSession();
    //         }
    //     } finally {
    //         const isUserAuthenticated = authState?.isAuthenticated;
    //         if (isUserAuthenticated) {
    //             await dispatch(logEventToBackEnd('LOGOUT_SUCCESS'));
    //             await oktaAuth.tokenManager.clear();
    //             await oktaAuth.clearStorage();
    //             dispatch(logout());
    //         }
    //     }
    // };

    // Reset all reducer to their initial states
    const clearAllStateData = async () => {
        dispatch(advisorConsoleActions.resetAdviceConsoleReducer());
        dispatch(initializeGoals());
        dispatch(resetAdviceAdminReducer());
        dispatch(initializeAdviceClient());
    };

    const handleLogout = () => {
        void oktaAuth
            ?.signOut({
                clearTokensBeforeRedirect: true,
                postLogoutRedirectUri: window.origin + '/login',
                revokeAccessToken: true
            })
            .finally(() => {
                const isUserAuthenticated = authState?.isAuthenticated;
                if (isUserAuthenticated) {
                    dispatch(logEventToBackEnd('LOGOUT_SUCCESS'));
                    dispatch(logout());
                    clearAllStateData();
                }
            });
    };

    const handleAgreementComplete = async () => {
        await dispatch(
            saveUserAgreementsSettings({
                click_wrap_agreements: {
                    accepted: true,
                    userAggrementAcceptedTimeStamp: dayjs().format('YYYYMMDDHHmmss'),
                    agreements: [
                        {
                            agreement_name: 'UserAgreement',
                            acceptedUserAgreementVersion: user?.userAgreement?.version
                        }
                    ]
                }
            })
        );
    };

    const handleEditSuccess = async (deletedAccounts: string[] = [], formFields?: any[]) => {
        const formFieldsData = formFields || adviceGoals.formFields;
        if (formFieldsData.length > 0) {
            dispatch(showLoader());
            const newFormFields = formFieldsData.map((goal: any) => {
                return {
                    ...goal,
                    data: {
                        ...goal.data,
                        sources: {
                            ...goal.data.sources,
                            incomeSource: goal.data.sources.incomeSource.filter(
                                (source: any) => !deletedAccounts.includes(source.accountNumber)
                            ),
                            investmentSource: goal.data.sources.investmentSource.filter(
                                (source: any) => !deletedAccounts.includes(source.accountNumber)
                            )
                        }
                    }
                };
            });
            await dispatch(
                updateGoal({
                    ...{ ...adviceGoals, formFields: newFormFields },
                    global: {
                        editMode: true,
                        ...adviceGoals?.global,
                        banner: {
                            visible: true,
                            type: 'attention',
                            isGoalSummary: adviceClient.step === 2 ? false : true
                        },
                        ...(adviceGoals.global?.enablePDFGenerate && { enablePDFGenerate: false })
                    }
                })
            );
            dispatch(removeLoader());
        }
    };

    const handleRiskProfileEditSuccess = async () => {
        dispatch(showLoader());
        await dispatch(
            updateGoal({
                ...adviceGoals,
                global: {
                    ...adviceGoals?.global,
                    status: 'incomplete',
                    enablePDFGenerate: false,
                    banner: {
                        visible: true,
                        type: 'attention',
                        isGoalSummary: adviceClient.step === 1,
                        message: t('BANNER_ATTENTION_MESSAGE_SUMMARY')
                    }
                }
            })
        );
        dispatch(removeLoader());
    };

    useEffect(() => {
        const checkTokenExpiry = () => {
            if (authState?.accessToken) {
                const { expiresAt, accessToken } = authState?.accessToken;
                const currentTime = Math.floor(Date.now() / 1000);
                //console.log(currentTime);
                //console.log(expiresAt);
                if (!accessToken || expiresAt < currentTime) {
                    handleLogout();
                }
            }
        };
        const interval = setInterval(checkTokenExpiry, 15000);
        return () => clearInterval(interval);
    }, [authState?.accessToken]);

    useEffect(() => {
        if (authState && !isAuthenticated) {
            const originalUri = toRelativeUrl(window.location.origin, window.location.href);
            oktaAuth.setOriginalUri(originalUri);
            navigate(originalUri);
        }
    }, [authState, isAuthenticated]);

    if (!isAuthenticated) {
        return <Spinner enabled />;
    }

    if (global.showAgreementModal) {
        return (
            <ClickWrapAgreement
                title="User Terms"
                open={global.showAgreementModal}
                agreements={[
                    {
                        title: 'Terms of Use Agreement',
                        content: user?.userAgreement?.text
                    }
                ]}
                onClose={handleLogout}
                onComplete={() => handleAgreementComplete()}
            />
        );
    }
    console.log(pathname, 'location');
    if (
        user.userRole !== 'admin' &&
        user.userRole !== 'ftadmin' &&
        !allowedPathsForAdvisor.includes(window.location.pathname)
    ) {
        return <Navigate to="/clients" />;
    }

    return (
        <PageContainer>
            <Header />
            <StyledContainer isAuthLoading={isAuthLoading || isAdvisorConsoleLoading}>
                <SideBarContainer>
                    {displaySidebarPaths.includes(pathname) ? (
                        <>
                            <LeftPanel
                                data={leftPanelData}
                                onEditIncomeClick={() => setActiveDrawer('incomeSource')}
                                onEditInvestmentClick={() => setActiveDrawer('wealthSource')}
                                onEditRiskProfile={() => setActiveDrawer('riskProfile')}
                            />
                            {activeDrawer === 'incomeSource' && (
                                <AddIncomeSourcesDrawer
                                    setOpenDrawer={(flag: boolean) => setActiveDrawer(flag ? 'incomeSource' : null)}
                                    openDrawer={activeDrawer === 'incomeSource'}
                                    mode="edit"
                                    onEditSuccess={handleEditSuccess}
                                />
                            )}
                            {activeDrawer === 'wealthSource' && (
                                <AddInvestmentAccounts
                                    setOpenDrawer={(flag: boolean) => setActiveDrawer(flag ? 'wealthSource' : null)}
                                    openDrawer={activeDrawer === 'wealthSource'}
                                    mode="edit"
                                    onEditSuccess={handleEditSuccess}
                                />
                            )}
                            {activeDrawer === 'riskProfile' && (
                                <RiskProfileDrawer
                                    setOpenDrawer={(flag: boolean) => setActiveDrawer(flag ? 'riskProfile' : null)}
                                    openDrawer={activeDrawer === 'riskProfile'}
                                    mode="edit"
                                    onEditSuccess={handleRiskProfileEditSuccess}
                                />
                            )}
                            {(activeDrawer === 'client' || activeDrawer === 'relationship') && (
                                <PersonalInfoAndRelationshipsDrawer
                                    setOpenDrawer={(flag: boolean) => setActiveDrawer(flag ? 'client' : null)}
                                    openDrawer={activeDrawer === 'client' || activeDrawer === 'relationship'}
                                    isRelationship={activeDrawer === 'relationship'}
                                />
                            )}
                        </>
                    ) : null}
                </SideBarContainer>
                <StyledMainContainer>
                    <div style={{ flexGrow: 1 }}>
                        <Outlet />
                    </div>
                    <Footer
                        showDisclaimerFooter
                        primaryText={
                            <Trans
                                i18nkey="DESCRIPTION_FOOTER_TWO"
                                defaults={t('DESCRIPTION_FOOTER_TWO')}
                                values={{ YEAR: getCurrentYear() }}
                                components={{ bold: <strong /> }}
                            />
                        }
                        links={[
                            {
                                label: 'Terms of Use',
                                link: `${process.env.PUBLIC_URL}/assets/pdf/en/Digital_Advisor_Terms_of_Use.pdf`
                            },
                            {
                                label: 'Privacy Notice',
                                link: 'https://www.franklintempletonglobal.com/privacy'
                            }
                        ]}
                        eulaVariant={true} // to hide the detailed footer
                    />
                </StyledMainContainer>
            </StyledContainer>
        </PageContainer>
    );
};
4;
