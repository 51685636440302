import { useOktaAuth } from '@okta/okta-react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../common/hooks';
import { resetAdviceAdminReducer } from '../../../features/adviceAdmin';
import { advisorConsoleActions } from '../../../features/advisorConsole';
import { initializeAdviceClient } from '../../../features/client';
import { initializeGoals } from '../../../features/client-goals';
import { logEventToBackEnd } from '../../../features/global/globalSlice';
import { AlertDialog } from '../AlertDialog/AlertDialog';
import { Nav } from '../Nav';

export const Header = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { authState, oktaAuth } = useOktaAuth();
    const [popupOpen, setPopupOpen] = useState(false);
    const { user } = useAppSelector((state) => state.auth);

    // Reset all reducer to their initial states
    const clearAllStateData = async () => {
        dispatch(advisorConsoleActions.resetAdviceConsoleReducer());
        dispatch(initializeGoals());
        dispatch(resetAdviceAdminReducer());
        dispatch(initializeAdviceClient());
    };

    // TODO:: Delet this
    // const handleLogout = async () => {
    //     updateGlobalLoaderState(true);
    //     try {
    //         const sessionExists = await oktaAuth.session.exists();
    //         if (sessionExists) {
    //             await oktaAuth.closeSession();
    //         }
    //     } finally {
    //         const isUserAuthenticated = authState?.isAuthenticated;
    //         if (isUserAuthenticated) {
    //             await dispatch(logEventToBackEnd('LOGOUT_SUCCESS'));
    //             await oktaAuth.tokenManager.clear();
    //             await oktaAuth.clearStorage();
    //             dispatch(logout());
    //             clearAllStateData();
    //         }
    //     }
    // };

    const handleLogout = () => {
        void oktaAuth
            ?.signOut({
                clearTokensBeforeRedirect: true,
                postLogoutRedirectUri: window.origin + '/login',
                revokeAccessToken: true
            })
            .finally(() => {
                const isUserAuthenticated = authState?.isAuthenticated;
                if (isUserAuthenticated) {
                    dispatch(logEventToBackEnd('LOGOUT_SUCCESS'));
                    clearAllStateData();
                }
            });
    };

    const handleAdminPortalNavigate = () => {
        navigate('/admin');
    };

    const onConsoleClick = () => {
            navigate('/clients');
    };

    const navItems = [
        {
            label: 'CONSOLE',
            onClick: onConsoleClick
        }
        // Commenting resource tab for now ,
        // {
        //     label: 'RESOURCES',
        //     to: '/resources',
        //     items: [
        //         {
        //             label: 'TUTORIALS',
        //             to: '/resources/tutorials'
        //         },
        //         {
        //             label: 'ARTICLES',
        //             to: '/resources/articles'
        //         },
        //         {
        //             label: 'FAQ',
        //             to: '/resources/faq'
        //         }
        //     ]
        // }
    ];
    const handleDismiss = () => {
        setPopupOpen(false);
    };
    return (
        <>
            <Nav
                items={navItems}
                user={user}
                onClickLogout={() => setPopupOpen(true)}
                onClickAdmin={handleAdminPortalNavigate}
            />
            <AlertDialog
                type="confirmation"
                open={popupOpen}
                icon="logout"
                handleClose={handleDismiss}
                content={`${t('LOGOUT_MODAL_CONTENT')}`}
                title={t('TEXT_MENU_LOGOUT')}
                aria-label="log-out-confirmation-modal"
                data-testid="log-out-confirmation-modal"
                cancelButtonLabel={t('DELETE_CONFIRMATION_MODAL_CANCEL_TEXT')}
                confirmButtonLabel={t('TEXT_MENU_LOGOUT')}
                onCancellation={handleDismiss}
                onConfirmation={handleLogout}
            />
        </>
    );
};
export default Header;
