import { useOktaAuth } from '@okta/okta-react';
import { memo } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import NotFound from '../pages/404';
import Clients from '../pages/Clients';
import Admin from '../pages/Admin';
import ClientJourney from '../pages/ClientJourney';
import FinancialAdvisor from '../pages/FinancialAdvisor';
import LoginContainer from '../pages/LoginContainer';
import Resources from '../pages/Resources';
import { User } from '../pages/User';
import { RequiredAuth } from './SecureRoute';

const AppRoutes = () => {
    const okta = useOktaAuth();
    const isAuthenticated = okta?.authState?.isAuthenticated;
    return (
        <Routes>
            <Route path="/" element={isAuthenticated ? <Navigate to="/clients" /> : <Navigate to="/login" />} />
            <Route path="/login" element={<LoginContainer />} />
            <Route element={<RequiredAuth />}>
                <Route path="/admin" element={<Admin />} />
                <Route path="/add-user" element={<FinancialAdvisor />} />
                <Route path="/clients" element={<Clients />} />
                <Route path="/creategoals" element={<ClientJourney />} />
                <Route path="/createclient" element={<ClientJourney />} />
                <Route path="/resources" element={<Resources />} />
                <Route path="/reviewplan" element={<ClientJourney />} />
                <Route path="/user" element={<User />} />
                <Route path="*" element={<NotFound />} />
            </Route>
        </Routes>
    );
};

export default memo(AppRoutes);
