import { styled } from '@mui/material';
import TargetImage from '../../../assets/icons/target_rounded.svg';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../../common/hooks';
import { initializeAdviceClient } from '../../../features/client';
import { initializeGoals } from '../../../features/client-goals';
import { BasicButton, Typography } from '../../../components/atoms';
import { useTranslation } from 'react-i18next';
import { PATPalette } from '../../../themes/palette';

export const EmptyClients = () => {
    const navigate = useNavigate();

    const { t } = useTranslation();

    const dispatch = useAppDispatch();

    const navigateToCreateClient = () => {
        // initialize page upon landing on /createclient
        dispatch(initializeAdviceClient());
        dispatch(initializeGoals());
        navigate('/createclient');
    };

    return (
        <ClientConsoleContainer>
            <EmptyStateContainer>
                <TargetIcon loading="eager" src={TargetImage} />
                <StyledTitle variant="h2" label={t('TEXT_LETS_GET_STARTED')} />
                <BasicButton
                    sx={{
                        padding: '10px 24px'
                    }}
                    variant="contained"
                    onClick={navigateToCreateClient}
                >
                    {t('TEXT_CREATE_A_NEW_PLAN')}
                </BasicButton>
            </EmptyStateContainer>
        </ClientConsoleContainer>
    );
};

const ClientConsoleContainer = styled('div')(() => ({
    display: 'flex',
    padding: '0 40px',
    minHeight: 'calc(100vh - 80px)',
    boxSizing: 'border-box',
    justifyContent: 'center',
    alignItems: 'center'
}));

const TargetIcon = styled('img')(({}) => ({
    height: '183px',
    width: '183px',
    marginBottom: '42px',
    pointerEvents: 'none'
}));

const EmptyStateContainer = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: '365px'
}));

const StyledTitle = styled(Typography)(() => ({
    marginBottom: '27px',
    color: PATPalette.neutral.grey[500],
    textAlign: 'center',
    fontVariantNumeric: 'lining-nums tabular-nums',
    fontFamily: 'TT Commons Pro, sans-serif',
    fontSize: '30px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '32px'
}));
