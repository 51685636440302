import { useEffect, useMemo, useState } from 'react';
import { DrawerComp, FundingSourcesWidget, InfoTooltip } from '../../components/molecules';
import { styled } from '@mui/material/styles';
import { TextboxInput, ComboSelect, Typography, BasicDatePicker } from '../../components/atoms';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation, Trans } from 'react-i18next';
import { useAppSelector, useAppDispatch } from '../../common/hooks';
import { createGoal, updateBanner, updateGoal } from '../../features/client-goals';
import { RetirementGoalsFormType, GoalPriorityEnum, GoalTypeProps } from '../../common/types/clients-entity';
import dayjs, { Dayjs } from 'dayjs';
import { v4 as uuidv4 } from 'uuid';
import { RETIREMENT_GOAL } from '../../common/constants';
import { Source } from '../../components/molecules/MonetarySource/MonetarySource';
import { getHeaderIconFromType, getYYYYMMDDfromDate } from '../../common/helper';
import { Box, Grid } from '@mui/material';
import { updateGlobalLoaderState } from '../../features/global/globalSlice';
import { GoalType } from '../../components/molecules/GoalType/GoalType';
import { customPalette, PATPalette } from '../../themes/palette';
import { deepEqual, formatCurrency, removeNonNumeric, sortObjectKeysAlphabetically } from '../../utils';
export interface RetirementGoalProps {
    // eslint-disable-next-line no-unused-vars
    handleClose: (flag: boolean) => void;
    onSubmit?: () => void;
    loading?: boolean;
    heading?: string;
    subHeading?: string;
    selectedGoal?: boolean | GoalTypeProps;
    goalData?: RetirementGoalsFormType;
    yearsInRetirement?: number;
    open: boolean;
    currency?: string;
}

export const ItemContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    gap: '8px',
    '& a': {
        color: theme.palette.background.paper
    }
}));

export const Footer = styled('div')(() => ({
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    flexDirection: 'column',
    fontSize: '10px',
    height: '100%'
}));

const GoalFormWrapper = styled('div')(() => ({
    overflowY: 'auto',
    overflowX: 'hidden',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    fontSize: '14px'
}));

export const ErrorSpan = styled(Typography)(() => ({
    color: PATPalette.secondary.berry[400],
    display: 'flex',
    marginTop: 5,
    fontStyle: 'italic'
}));

export const SavedFundsWrapper = styled('div')(() => ({
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column'
}));

export const Wrap = styled('div')(() => ({
    position: 'relative'
}));

interface FrequencyProps {
    selected: boolean;
}

export const Frequency = styled('div')<FrequencyProps>(({ selected }) => ({
    width: '35px',
    height: '35px',
    borderRadius: '50%',
    aspectRatio: '1/1',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: '1px solid #E0E0E0',
    cursor: 'pointer',
    backgroundColor: selected ? '#E0E0E0' : 'transparent'
}));

export const Placeholder = styled('div')(() => ({
    height: '21px'
}));

export const StyledInfoText = styled(Typography)(() => ({
    variant: 'body1',
    fontStyle: 'italic',
    color: customPalette.universalCard.subTextFontColor,
    fontSize: '16px',
    lineHeight: '12px',
    fontWeight: 400
}));

export const RetirementGoals = ({
    handleClose,
    selectedGoal,
    open,
    goalData,
    yearsInRetirement
}: RetirementGoalProps) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const {
        orgInfo,
        globalConfig: { currencySymbol }
    } = useAppSelector((state) => state.global);
    const currency = currencySymbol === 'en' || currencySymbol === 'es' ? '$' : 'CAD $';
    const clientData = useAppSelector((state) => state.adviceClient);
    const adviceGoals = useAppSelector((state) => state.adviceGoals || []);
    const banner = adviceGoals?.global?.banner;
    const originalGoalsDataRetirement: typeof adviceGoals = JSON.parse(JSON.stringify(adviceGoals));

    const investmentSourceData = useMemo(
        () =>
            clientData?.accounts.investmentsSources.reduce((acc: Record<string, Source>, curr) => {
                acc[curr.accountId] = {
                    accountNumber: curr.accountId,
                    accountName: curr.accountName,
                    frequency: curr.frequency,
                    isSelected: goalData
                        ? adviceGoals.formFields?.some((goal) =>
                              goal?.data?.sources?.investmentSource?.some(
                                  (inc: any) =>
                                      inc.accountNumber === curr.accountId &&
                                      inc.isSelected &&
                                      goal?.goalId === goalData?.goalId
                              )
                          )
                        : false,
                    recurringContributionsStartDate: getYYYYMMDDfromDate(dayjs(curr.recurringContributionsStartDate)),
                    recurringContributionsEndDate: getYYYYMMDDfromDate(dayjs(curr.recurringContributionsEndDate)),
                    isRCSelected: goalData
                        ? adviceGoals.formFields?.some((goal) =>
                              goal?.data?.sources?.investmentSource?.some(
                                  (inv: { accountNumber: string; isSelected: boolean }) =>
                                      inv.accountNumber === curr.accountId &&
                                      inv.isSelected &&
                                      goal?.goalId === goalData?.goalId
                              )
                          )
                        : false,
                    accountBalance: curr.amount,
                    recurringContributions: curr.recurringContributions || 0,
                    disabled: goalData
                        ? adviceGoals.formFields?.some((goal) =>
                              goal?.data?.sources?.investmentSource?.some(
                                  (inv: { accountNumber: string; isSelected: boolean }) =>
                                      inv.accountNumber === curr.accountId &&
                                      inv.isSelected &&
                                      goal?.goalId !== goalData?.goalId
                              )
                          )
                        : adviceGoals.formFields?.some((goal) =>
                              goal?.data?.sources?.investmentSource?.some(
                                  (inv: { accountNumber: string; isSelected: boolean }) =>
                                      inv.accountNumber === curr.accountId && inv.isSelected
                              )
                          )
                };
                return acc;
            }, {}),
        [adviceGoals.formFields, open]
    );

    const incomeSourceData = useMemo(
        () =>
            clientData?.accounts.incomeSources.reduce((acc: Record<string, Source>, curr) => {
                acc[curr.accountId] = {
                    accountNumber: curr.accountId,
                    accountName: curr.sourceName,
                    beginningYear: curr.beginningYear,
                    endYear: curr.endYear,
                    frequency: curr.frequency,
                    isSelected: goalData
                        ? adviceGoals.formFields?.some((goal) =>
                              goal?.data?.sources?.incomeSource?.some(
                                  (inc: any) =>
                                      inc.accountNumber === curr.accountId &&
                                      inc.isSelected &&
                                      goal?.goalId === goalData?.goalId
                              )
                          )
                        : false,
                    accountBalance: curr.frequency === 'Annually' ? curr.amount : 12 * curr.amount,
                    disabled: goalData
                        ? adviceGoals.formFields?.some((goal) =>
                              goal?.data?.sources?.incomeSource?.some(
                                  (inc: any) =>
                                      inc.accountNumber === curr.accountId &&
                                      inc.isSelected &&
                                      goal?.goalId !== goalData?.goalId
                              )
                          )
                        : adviceGoals.formFields?.some((goal) =>
                              goal?.data?.sources?.incomeSource?.some(
                                  (inc: any) => inc.accountNumber === curr.accountId
                              )
                          )
                };
                return acc;
            }, {}),
        [adviceGoals.formFields, open]
    );

    const [incomeSource, setIncomeSource] = useState(incomeSourceData);
    const [investmentSource, setInvestmentSource] = useState(investmentSourceData);
    const [fundingSourceError, setFundingSourceError] = useState<string | null>(null);
    const incomeSourceSelected = Object.values(incomeSource).filter((source) => source.isSelected);
    const investmentSourceSelected = Object.values(investmentSource).filter((source) => source.isSelected);
    const initialValues = {
        frequency: undefined,
        goalId: '',
        goalName: '',
        goalPriority: undefined,
        goalType: 'Retirement Goal',
        planStartRetirement: null as Dayjs | null,
        targetedRetirementIncome: undefined,
        yearsInRetirement: undefined,
        goalAmount: undefined
    };
    const {
        control,
        formState: { errors, isValid },
        trigger,
        reset,
        getValues,
        setValue,
        watch,
        setError,
        clearErrors
    } = useForm<RetirementGoalsFormType>({
        mode: 'all',
        reValidateMode: 'onChange',
        defaultValues: initialValues
    });

    const submitFunds = async () => {
        try {
            const data = getValues();
            await trigger();
            if (
                !goalData &&
                isValid &&
                clientData?._id &&
                typeof selectedGoal !== 'boolean' &&
                (incomeSourceSelected.length > 0 || investmentSourceSelected.length > 0)
            ) {
                const isDuplicateName = adviceGoals?.formFields
                    .filter((goal) => (goalData ? goal.goalId !== (goalData as RetirementGoalsFormType).goalId : true))
                    .some((i) => i.data.goalName.toLowerCase() === data.goalName.toLowerCase());
                if (isDuplicateName) {
                    setError('goalName', { message: t('DUPLICATE_NAME') });
                    return;
                } else {
                    clearErrors('goalName');
                }
                dispatch(updateGlobalLoaderState(true));
                const generatedId = uuidv4();
                data.goalType = selectedGoal?.type || RETIREMENT_GOAL;
                data.wealthGoalType = selectedGoal?.title;
                data.sources = {
                    incomeSource: incomeSourceSelected,
                    investmentSource: investmentSourceSelected
                };
                var formatedAmountValTargetIncome = Number(removeNonNumeric(data['targetedRetirementIncome']));
                data['targetedRetirementIncome'] =
                    data['frequency'] === 'Monthly'
                        ? Number(formatedAmountValTargetIncome) * 12
                        : formatedAmountValTargetIncome;
                data['goalAmount'] = data['goalAmount'] ? Number(removeNonNumeric(data['goalAmount'])) : 0;
                if (data['yearsInRetirement'])
                    data['targetDate'] = data['planStartRetirement']?.add(data['yearsInRetirement'], 'year');
                //data['targetDate'] = data['planStartRetirement']?.add(data['yearsInRetirement'], 'year').subtract(1, 'day');
                data['targetDate'] = getYYYYMMDDfromDate(data['targetDate']);
                delete data['yearsInRetirement'];
                delete data['frequency'];
                data['planStartRetirement'] = getYYYYMMDDfromDate(data['planStartRetirement']);
                const nextIndex = adviceGoals.formFields.length + 1;
                const newFormField = {
                    goalId: generatedId,
                    data: { ...data, index: nextIndex, goalId: generatedId, createdAt: new Date().toISOString() }
                };
                let originalGoalsData: typeof adviceGoals = JSON.parse(JSON.stringify(adviceGoals));
                const updatedGoalsData = Object.assign({}, originalGoalsData);
                updatedGoalsData.formFields = updatedGoalsData?.formFields
                    ? [...updatedGoalsData?.formFields, newFormField]
                    : [newFormField];
                updatedGoalsData.domainEmail = orgInfo?.orgEmail ?? '';
                updatedGoalsData.goeConfigEmail = orgInfo?.orgEmail ?? '';
                updatedGoalsData.riskProfile = clientData.riskProfile;
                updatedGoalsData.assignedClientId = clientData._id;
                updatedGoalsData.error = false;
                updatedGoalsData.loading = false;
                if (updatedGoalsData.global?.hasRunPipe) {
                    updatedGoalsData.global = {
                        ...updatedGoalsData.global,
                        status: 'incomplete',
                        banner: {
                            ...updatedGoalsData.global.banner,
                            visible: true,
                            type: 'attention',
                            isGoalSummary: true,
                            message: t('GOAL_DELETE_BANNER_ATTENTION_MESSAGE_SUMMARY')
                        }
                    };
                }
                if (updatedGoalsData.global?.enablePDFGenerate) {
                    updatedGoalsData.global.enablePDFGenerate = false;
                }
                !updatedGoalsData._id
                    ? await dispatch(createGoal(updatedGoalsData))
                    : await dispatch(updateGoal(updatedGoalsData));
                dispatch(updateGlobalLoaderState(false));
                reset();
                setIncomeSource(incomeSourceData);
                setInvestmentSource(investmentSourceData);
                handleClose(false);
            } else {
                handleEditClick();
            }
        } catch (error) {
            console.error('Error in creating goals', error);
        }
    };

    const handleGoalPriorityChange = (selectedValue: GoalPriorityEnum) => {
        setValue('goalPriority', selectedValue, { shouldValidate: true });
    };

    const handleEditClick = async () => {
        const data = getValues();
        const isDuplicateName = adviceGoals?.formFields
            .filter((goal) => (goalData ? goal.goalId !== (goalData as RetirementGoalsFormType).goalId : true))
            .some((i) => i.data.goalName.toLowerCase() === data.goalName.toLowerCase());
        if (isDuplicateName) {
            setError('goalName', { message: t('DUPLICATE_NAME') });
            return;
        } else {
            clearErrors('goalName');
        }
        dispatch(updateGlobalLoaderState(true));
        let originalGoalsData: typeof adviceGoals = JSON.parse(JSON.stringify(adviceGoals));
        const updatedGoalsData = Object.assign({}, originalGoalsData);
        updatedGoalsData?.formFields.forEach((item) => {
            const itemData = item['data'];
            if (item.goalId === goalData?.goalId && itemData.goalType === 'retirement') {
                itemData.sources = {
                    incomeSource: incomeSourceSelected,
                    investmentSource: investmentSourceSelected
                };
                if (data['goalAmount']) {
                    let amountVal = data['goalAmount'];
                    var formatedAmountVal = Number(removeNonNumeric(amountVal));
                    itemData['goalAmount'] = formatedAmountVal;
                } else {
                    itemData['goalAmount'] = 0;
                }
                itemData['planStartRetirement'] =
                    typeof data['planStartRetirement'] === 'string'
                        ? data['planStartRetirement']
                        : getYYYYMMDDfromDate(data['planStartRetirement']);
                itemData['goalPriority'] = data['goalPriority'];
                itemData['goalName'] = data['goalName'];
                var formatedAmountValTargetIncome = Number(removeNonNumeric(data['targetedRetirementIncome']));
                itemData['targetedRetirementIncome'] =
                    data['frequency'] === 'Monthly'
                        ? Number(formatedAmountValTargetIncome) * 12
                        : formatedAmountValTargetIncome;
                itemData['frequency'] = data['frequency'];
                itemData['updatedAt'] = new Date().toISOString();
                if (data['yearsInRetirement']) {
                    if (typeof data['planStartRetirement'] === 'string') {
                        itemData['targetDate'] = addYears(data);
                    } else {
                        itemData['targetDate'] = data['planStartRetirement']?.add(data['yearsInRetirement'], 'year');
                        itemData['targetDate'] = getYYYYMMDDfromDate(itemData['targetDate']);
                    }
                }
            }
        });
        if (goalData && !deepEqual(originalGoalsDataRetirement.formFields, updatedGoalsData.formFields)) {
            if (updatedGoalsData.global.hasRunPipe) {
                updatedGoalsData.global.status = 'incomplete';
            }
            if (updatedGoalsData.global?.enablePDFGenerate) {
                updatedGoalsData.global.enablePDFGenerate = false;
            }
            await dispatch(updateGoal(updatedGoalsData));
            dispatch(
                updateBanner({
                    ...banner,
                    visible: true,
                    type: 'attention',
                    isGoalSummary: true,
                    message: t('GOAL_DELETE_BANNER_ATTENTION_MESSAGE_SUMMARY')
                })
            );
        }
        reset();
        setIncomeSource(incomeSourceData);
        setInvestmentSource(investmentSourceData);
        dispatch(updateGlobalLoaderState(false));
        handleClose(false);
    };

    useEffect(() => {
        if (goalData) {
            reset({
                goalAmount: goalData?.goalAmount,
                goalId: goalData?.goalId,
                goalPriority: goalData?.goalPriority,
                targetedRetirementIncome: goalData?.targetedRetirementIncome,
                goalName: goalData?.goalName,
                yearsInRetirement: yearsInRetirement,
                planStartRetirement: dayjs(goalData?.planStartRetirement, 'DD-MM-YYYY'),
                frequency: 'Annually'
            });
            setIncomeSource(incomeSourceData);
            setInvestmentSource(investmentSourceData);
        }
    }, []);

    const handleAmountChange = (name: 'targetedRetirementIncome' | 'goalAmount', value: string) => {
        const numericValue = Number(removeNonNumeric(value));
        setValue(name, isNaN(numericValue) ? 0 : numericValue, {
            shouldValidate: true,
            shouldDirty: true
        });
    };

    const addYears = (itemData: any) => {
        const dateStr =
            typeof itemData['planStartRetirement'] === 'string'
                ? itemData['planStartRetirement']
                : getYYYYMMDDfromDate(itemData['planStartRetirement']);
        const [day1, month1, year1] = dateStr.split('-').map(Number);
        const date = new Date(year1, month1 - 1, day1);
        const newDate = date.setFullYear(date.getFullYear() + parseInt(itemData['yearsInRetirement']));
        const updatedDate = new Date(newDate);
        // Extract day, month, and year
        const day = updatedDate.getDate();
        const month = updatedDate.getMonth() + 1;
        const year = updatedDate.getFullYear();
        const formattedDay: string = day < 10 ? '0' + day : day.toString();
        const formattedMonth: string = month < 10 ? '0' + month : month.toString();
        // Format the date as dd-mm-yyyy
        const formattedDate: string = formattedDay + '-' + formattedMonth + '-' + year;
        return formattedDate;
    };

    const header: any = goalData ? goalData?.title : typeof selectedGoal !== 'boolean' ? selectedGoal?.heading : '';
    const checkIfFormIsEdited = () => {
        let currentGoal = goalData ? JSON.parse(JSON.stringify(goalData)) : JSON.parse(JSON.stringify(initialValues));
        let data = watch();
        // custom hasChanged logic for custom input component FundingSourcesWidget
        const fundingSourceHasChanged =
            !Object.values(investmentSourceData).every(
                (inv) =>
                    (inv.isSelected &&
                        investmentSourceSelected.find(
                            (invSelected) => inv.accountNumber === invSelected.accountNumber
                        )) ||
                    (!inv.isSelected &&
                        !investmentSourceSelected.find(
                            (invSelected) => inv.accountNumber === invSelected.accountNumber
                        ))
            ) ||
            !Object.values(incomeSourceData).every(
                (inv) =>
                    (inv.isSelected &&
                        incomeSourceSelected.find((invSelected) => inv.accountNumber === invSelected.accountNumber)) ||
                    (!inv.isSelected &&
                        !incomeSourceSelected.find((invSelected) => inv.accountNumber === invSelected.accountNumber))
            );
        if (currentGoal && data?.targetedRetirementIncome) {
            var formatedAmountValTargetIncome = Number(removeNonNumeric(data['targetedRetirementIncome']));
            data['targetedRetirementIncome'] =
                data['frequency'] === 'Monthly'
                    ? Number(formatedAmountValTargetIncome) * 12
                    : formatedAmountValTargetIncome;
            data['goalAmount'] = data['goalAmount'];
            if (data['planStartRetirement'])
                data['planStartRetirement'] = getYYYYMMDDfromDate(data['planStartRetirement']);
            if (currentGoal?.title) delete currentGoal['title'];
            if (currentGoal?.riskProfile && currentGoal?.targetDate) {
                delete currentGoal?.isIWSApplied;
                delete currentGoal?.accounts;
                delete currentGoal?.riskProfile;
                delete currentGoal?.targetDate;
                currentGoal.frequency = 'Annually';
                currentGoal.yearsInRetirement = yearsInRetirement;
                if (currentGoal?.recommendedWealthSplit != undefined) delete currentGoal.recommendedWealthSplit;
                data.goalType = currentGoal.goalType;
            }
        }

        if (currentGoal['yearsInRetirement'])
            currentGoal['yearsInRetirement'] = parseInt(currentGoal['yearsInRetirement']);
        return !(
            JSON.stringify(sortObjectKeysAlphabetically(currentGoal)) ===
                JSON.stringify(sortObjectKeysAlphabetically(data)) && !fundingSourceHasChanged
        );
    };
    const checkFundingSourceError = (source: Record<string, Source>, type: 'income' | 'investment') => {
        if (
            (!Object.values(source).find((sc) => sc.isSelected) &&
                type === 'income' &&
                investmentSourceSelected?.length === 0) ||
            (!Object.values(source).find((sc) => sc.isSelected) &&
                type === 'investment' &&
                incomeSourceSelected?.length === 0)
        )
            setFundingSourceError(t('FUNDING_SOURCES_ERROR_MSG'));
        else setFundingSourceError(null);
    };

    return (
        <DrawerComp
            open={open}
            buttonlabel="Complete"
            header={t(header?.toUpperCase() ?? '')}
            drawerIcon={
                <GoalType
                    type={
                        getHeaderIconFromType(
                            goalData
                                ? goalData?.title
                                : typeof selectedGoal !== 'boolean'
                                ? selectedGoal?.title
                                : 'Retirement'
                        )!
                    }
                />
            }
            handleClose={() => {
                reset();
                setIncomeSource(incomeSourceData);
                setInvestmentSource(investmentSourceData);
                setFundingSourceError(null);
                handleClose(false);
            }}
            onSubmit={submitFunds}
            disableSubmit={!isValid || (incomeSourceSelected.length === 0 && investmentSourceSelected.length === 0)}
            hasChange={checkIfFormIsEdited()}
        >
            <GoalFormWrapper>
                <StyledInfoText label={t('TEXT_ALL_REQUIRED_FIELDS')}></StyledInfoText>
                <ItemContainer sx={{ alignItems: 'flex-start', width: '100%' }}>
                    <Typography
                        variant="labels.large"
                        sx={{ color: PATPalette.neutral.grey[300] }}
                        label={`${t('TEXT_GOAL_NAME')} *`}
                    />
                    <Controller
                        name="goalName"
                        control={control}
                        rules={{
                            required: {
                                value: true,
                                message: t('TEXT_THIS_IS_REQUIRED_FIELD')
                            },
                            pattern: {
                                value: /^(?=.*[a-zA-Z0-9])[a-zA-Z0-9\s'(),.-]*$/,
                                message: t('TEXT_FULLNAME_VALIDATION')
                            }
                        }}
                        render={({ field: { onChange, onBlur, value, ref } }) => (
                            <TextboxInput
                                onBlur={onBlur}
                                onChange={onChange}
                                value={value}
                                inputRef={ref}
                                size="small"
                                error={!!errors.goalName}
                                type="text"
                                data-testid="retirement-goal-name"
                                placeholder={t('TEXT_GOAL_NAME')}
                                fullWidth
                            />
                        )}
                    />
                    {errors.goalName ? (
                        <ErrorSpan variant="subtitle2" role="alert" label={errors.goalName.message} />
                    ) : null}
                </ItemContainer>
                <ItemContainer sx={{ alignItems: 'flex-start', width: '100%' }}>
                    <Grid container alignItems="center">
                        <Typography
                            variant="labels.large"
                            sx={{ color: PATPalette.neutral.grey[300] }}
                            label={`${t('TEXT_GOAL_PRIORITY')} *`}
                        />
                        <InfoTooltip
                            message={
                                <Trans
                                    i18nKey="GOAL_PRIORITY_INFO_TEXT"
                                    components={{ strong: <strong />, br: <br /> }}
                                />
                            }
                        />
                    </Grid>
                    <Controller
                        name="goalPriority"
                        control={control}
                        rules={{
                            required: {
                                value: true,
                                message: t('TEXT_THIS_IS_REQUIRED_FIELD')
                            }
                        }}
                        render={({ field }) => (
                            <ComboSelect
                                {...field}
                                onSelectChange={handleGoalPriorityChange}
                                size="small"
                                selectedValue={field.value ?? ''}
                                options={Object.keys(GoalPriorityEnum).map((key) => ({
                                    label: GoalPriorityEnum[key as keyof typeof GoalPriorityEnum],
                                    value: GoalPriorityEnum[key as keyof typeof GoalPriorityEnum]
                                }))}
                                data-testid="goal-priority"
                            />
                        )}
                    />
                    {errors.goalPriority && (
                        <ErrorSpan variant="subtitle2" role="alert" label={errors.goalPriority.message} />
                    )}
                </ItemContainer>
                <Box sx={{ display: 'flex' }}>
                    <ItemContainer sx={{ alignItems: 'flex-start', marginRight: '8px', justifyContent: 'flex-start' }}>
                        <Grid container alignItems="center">
                            <Typography
                                variant="labels.large"
                                sx={{ color: PATPalette.neutral.grey[300] }}
                                label={`${t('INCOME_GOAL_AMOUNT')}*`}
                            />
                            <InfoTooltip
                                message={
                                    <Trans
                                        i18nKey="INCOME_GOAL_AMOUNT_INFO_TEXT"
                                        components={{ strong: <strong />, br: <br /> }}
                                    />
                                }
                            />
                        </Grid>
                        <Controller
                            name="targetedRetirementIncome"
                            control={control}
                            rules={{
                                required: { value: true, message: t('TEXT_THIS_IS_REQUIRED_FIELD') },
                                validate: {
                                    weight: (value) => (value < 0 ? t('TEXT_AMOUNT_VALIDATION') : undefined)
                                }
                            }}
                            render={({ field: { onBlur, value, ref } }) => (
                                <TextboxInput
                                    onBlur={() => {
                                        onBlur();
                                    }}
                                    onChange={(e) => {
                                        handleAmountChange('targetedRetirementIncome', e.target.value);
                                    }}
                                    value={value ? formatCurrency(value) : ''}
                                    inputRef={ref}
                                    size="small"
                                    error={!!errors.targetedRetirementIncome}
                                    type="text"
                                    data-testid="retirement-goal-amount"
                                    placeholder={currency}
                                    iconPlacement="start"
                                    fullWidth
                                />
                            )}
                        />
                        {errors.targetedRetirementIncome ? (
                            <ErrorSpan
                                variant="subtitle2"
                                role="alert"
                                label={errors.targetedRetirementIncome.message}
                            />
                        ) : null}
                    </ItemContainer>
                    <ItemContainer sx={{ flex: '0 0 40%', alignItems: 'flex-start', justifyContent: 'flex-start' }}>
                        <Typography
                            variant="labels.large"
                            sx={{ color: PATPalette.neutral.grey[300] }}
                            label={`${t('FREQUENCY')} *`}
                        />
                        <Controller
                            name="frequency"
                            control={control}
                            rules={{
                                required: {
                                    value: true,
                                    message: t('TEXT_THIS_IS_REQUIRED_FIELD')
                                }
                            }}
                            render={({ field: { onChange, onBlur, value, name } }) => (
                                <ComboSelect
                                    onSelectChange={onChange}
                                    onBlur={onBlur}
                                    selectedValue={value || ''}
                                    name={`retirement-${name}`}
                                    placeholder="Select"
                                    options={[
                                        { label: t('ANNUALLY'), value: 'Annually' },
                                        { label: t('MONTHLY'), value: 'Monthly' }
                                    ]}
                                />
                            )}
                        />
                        {errors.frequency && (
                            <ErrorSpan variant="subtitle2" role="alert" label={errors.frequency.message} />
                        )}
                    </ItemContainer>
                </Box>
                <Typography variant="inherit" label={`${t('RETIREMENT_GOALS_TEXT')} *`} />
                <ItemContainer sx={{ alignItems: 'flex-start', width: '100%' }}>
                    <Grid container alignItems="center">
                        <Typography
                            variant="labels.large"
                            sx={{ color: PATPalette.neutral.grey[300] }}
                            label={`${t('RETIREMENT_DATE')} *`}
                        />
                        <InfoTooltip
                            message={
                                <Trans
                                    i18nKey="RETIREMENT_DATE_INFO_TEXT"
                                    components={{ strong: <strong />, br: <br /> }}
                                />
                            }
                        />
                    </Grid>
                    <Controller
                        name="planStartRetirement"
                        control={control}
                        rules={{
                            required: { value: true, message: t('TEXT_THIS_IS_REQUIRED_FIELD') },
                            validate: {
                                validate: (value) => dayjs(value, 'YYYY', true).isValid(),
                                minDate: (value) => dayjs(value).isAfter(dayjs().subtract(1, 'day'))
                            }
                        }}
                        render={({ field: { onChange, onBlur, value } }) => (
                            <BasicDatePicker
                                enabled
                                type="single"
                                startDate={new Date().toDateString()}
                                value={value}
                                onChange={onChange}
                                onBlur={onBlur}
                                data-testid="retirement-date"
                                minErrorMsg={t('TEXT_VALID_DATE_ERROR')}
                                // isYyyy
                            />
                        )}
                    />
                    {errors.planStartRetirement && (
                        <ErrorSpan variant="subtitle2" role="alert" label={errors.planStartRetirement.message} />
                    )}
                </ItemContainer>
                <ItemContainer sx={{ alignItems: 'flex-start', width: '100%' }}>
                    <Grid container alignItems="center">
                        <Typography
                            variant="labels.large"
                            sx={{ color: PATPalette.neutral.grey[300] }}
                            label={`${t('YEARS_IN_RETIREMENT')} *`}
                        />
                        <InfoTooltip message={t('YEARS_IN_RETIREMENT_INFO_TEXT')} />
                    </Grid>
                    <Controller
                        name="yearsInRetirement"
                        control={control}
                        rules={{
                            required: {
                                value: true,
                                message: t('TEXT_THIS_IS_REQUIRED_FIELD')
                            },
                            validate: (value) => {
                                if (Number(value) < 1) return 'Enter a valid value.';
                                if (Number(value) < 2) return 'Please enter a minimum of 2 years.';
                            }
                        }}
                        render={({ field: { onChange, onBlur, value, ref } }) => (
                            <TextboxInput
                                onBlur={onBlur}
                                onChange={(e) => {
                                    const numericValue = e.target.value.replace(/[^0-9]/g, ''); // Allow only numeric input
                                    if (100 >= Number(numericValue) && Number(numericValue) >= 0)
                                        onChange(numericValue); // Update the value in the form
                                }}
                                value={value}
                                inputRef={ref}
                                size="small"
                                error={!!errors.yearsInRetirement}
                                type="text"
                                data-testid="goal-years-in-retirement"
                                placeholder={t('NUMERICAL_VALUE')}
                                fullWidth
                                min={1}
                            />
                        )}
                    />
                    {errors.yearsInRetirement ? (
                        <ErrorSpan variant="subtitle2" role="alert" label={errors.yearsInRetirement.message} />
                    ) : null}
                </ItemContainer>
                <ItemContainer sx={{ alignItems: 'flex-start', width: '100%' }}>
                    <Grid container alignItems="center">
                        <Typography variant="labels.large" label={`${t('GOAL_SUMMARY_BEQUEST_AMOUNT_TEXT')}`} />
                        <InfoTooltip
                            message={
                                <Trans
                                    i18nKey="GOAL_SUMMARY_BEQUEST_AMOUNT_TEXT_INFO_TEXT"
                                    components={{ strong: <strong />, br: <br /> }}
                                />
                            }
                        />
                    </Grid>
                    <Controller
                        name="goalAmount"
                        control={control}
                        rules={{
                            validate: {
                                weight: (value) => (value && value < 0 ? t('TEXT_AMOUNT_VALIDATION') : undefined)
                            }
                        }}
                        render={({ field: { onBlur, value, ref } }) => (
                            <TextboxInput
                                onBlur={() => {
                                    onBlur();
                                }}
                                onChange={(e) => {
                                    handleAmountChange('goalAmount', e.target.value);
                                }}
                                value={value ? formatCurrency(value) : ''}
                                inputRef={ref}
                                size="small"
                                error={!!errors.goalAmount}
                                type="text"
                                placeholder={currency}
                                data-testid="goal-amount"
                                fullWidth
                                iconPlacement="start"
                            />
                        )}
                    />
                    {errors.goalAmount ? (
                        <ErrorSpan variant="subtitle2" role="alert" label={errors.goalAmount.message} />
                    ) : null}
                </ItemContainer>
                <FundingSourcesWidget
                    incomeSource={incomeSource}
                    onIncomeSourceChange={(source) => {
                        setIncomeSource(source);
                        checkFundingSourceError(source, 'income');
                    }}
                    investmentSource={investmentSource}
                    onInvestmentSourceChange={(source) => {
                        setInvestmentSource(source);
                        checkFundingSourceError(source, 'investment');
                    }}
                    errorMsg={fundingSourceError}
                />
            </GoalFormWrapper>
        </DrawerComp>
    );
};
