import OktaSignIn from '@okta/okta-signin-widget';
import '@okta/okta-signin-widget/css/okta-sign-in.min.css';
import { get } from 'lodash';
import { useEffect, useRef } from 'react';
import FTLogo_Black from '../../assets/images/pdf/FTLogo_Black.svg';
import { OKTA_LOGIN_URL } from '../../common/constants';
import { useAppSelector } from '../../common/hooks';
import { addLogEvent } from '../../features/auth/authSlice';
import oktaAuth from '../../services/configs/oktaAuth';
import config from './config';
import './okta-sign-in-custom-theme.css';

const OktaLoginWidget = () => {
    const oktaWidgetContainerRef = useRef(null);
    const logo = useAppSelector((state) => state?.global?.globalConfig?.logo);
    const subdomain = useAppSelector((state) => state?.global?.globalConfig?.subdomain);
    const language = useAppSelector((state) => state?.global?.globalConfig?.language);
    const brandName = useAppSelector((state) => state?.global?.globalConfig?.disclaimers?.brandName);

    const isFranklinAdviceTenant = subdomain === 'advice' || subdomain === 'default';

    const {
        clientId,
        baseUrl,
        redirectUri,
        authParams: { issuer, dpop }
    } = config?.oidc;

    // Function to handle the OAuth response after the redirect
    async function handleOAuthResponse(res: any) {
        try {
            const tokens = get(res, 'tokens');
            void oktaAuth.handleLoginRedirect(tokens);
            if (!tokens) {
                throw new Error('Okta authentication error');
            }
        } catch (error) {
            await addLogEvent(
                OKTA_LOGIN_URL,
                'response',
                {
                    errorName: error,
                    errorType: 'Error parsing OAuth response'
                },
                'failed'
            );
            throw error;
        }
    }

    useEffect(() => {
        const widget = new OktaSignIn({
            baseUrl,
            clientId,
            redirectUri,
            authParams: { issuer, dpop },
            logo: isFranklinAdviceTenant ? FTLogo_Black : logo,
            logoText: brandName, // shows when logo fails to load.
            language: language ? language[0] : 'en' // shows language from tenantInfo if fails to load, defaults to English
        });

        if (oktaWidgetContainerRef.current) {
            widget.renderEl({ el: oktaWidgetContainerRef.current }, handleOAuthResponse);
        }

        return () => {
            widget.remove();
        };
    }, [logo]);

    return <article className="msq-okta-siw-container" ref={oktaWidgetContainerRef}></article>;
};

export default OktaLoginWidget;
