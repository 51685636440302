import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { setAutoFreeze } from 'immer';
import axios from 'axios';
import moment from 'moment';

import { uipApiInstance } from '../../services';
import { logout } from '../auth/authSlice';

setAutoFreeze(false);

type InvestmentFundOptions = {
    label: string;
    value: string;
    disabled?: boolean;
    order: number;
};

type InvestmentAccountType = {
    [key: string]: InvestmentFundOptions[];
};
type InvestmentLocationType = {
    [key: string]: InvestmentFundOptions[];
};

type InitialState = {
    loading: boolean;
    globalLoader: boolean;
    error?: null | undefined | any;
    showErrorModal?: boolean;
    globalConfig: {
        tenantType: string;
        termsOfUse: boolean;
        eula: boolean;
        language: string[];
        logo?: string;
        subdomain: string;
        currencySymbol: string;
        disclaimers: { brandName: string };
        [key: string]: any;
    };
    multiTenancyRefresh?: any;
    orgInfo?: {
        investmentAccountType?: InvestmentAccountType;
        investmentAccountLocation?: InvestmentLocationType;
        investmentContributionPercentage?: InvestmentAccountType;
        riskProfileAex?: any;
        incomeSourceType?: any;
        relationshipTypes?: any;
        orgEmail?: string;
    };
    showAgreementModal: boolean;
    portfolio: Array<any>;
    domainEmail?: string;
    responseGoalCalculator: Array<any>;
    goeConfig: any;
};

const initialState: InitialState = {
    loading: false,
    globalLoader: false,
    error: {},
    showErrorModal: false,
    multiTenancyRefresh: '',
    orgInfo: { orgEmail: '' },
    showAgreementModal: false,
    portfolio: [],
    domainEmail: '',
    responseGoalCalculator: [{}],
    goeConfig: {},
    globalConfig: {
        tenantType: 'Trial',
        language: ['en'],
        currencySymbol: 'en',
        termsOfUse: true,
        eula: true,
        logo: undefined,
        subdomain: '',
        disclaimers: { brandName: '' }
    }
};

const makeUseMemoRun = () => `#${Math.floor(Math.random() * 16777215).toString(16)}`;

export const logEventToBackEnd = createAsyncThunk('globalState/logEventToBackEnd', async (action: string) => {
    const response = await uipApiInstance({
        method: 'POST',
        url: '/api/advisorportal/actionsaudit',
        data: {
            action
        }
    });

    return response;
});

export const getTenantBasedConfig = createAsyncThunk(
    'globalState/getTenantBasedConfig',
    async ({ tenantId, i18n }: { tenantId: string; i18n: any }, { rejectWithValue, dispatch }) => {
        try {
            const response = await axios({
                method: 'GET',
                baseURL: process.env.REACT_APP_UIP_API_URL,
                url: `/api/advice/gettenantinfodns?subdomain=${tenantId}`,
                withCredentials: false
            });
            const tenantConfigs = response.data.tenantConfig?.sort((a: any, b: any) => {
                const convertToTime = (date: any) => moment(date).valueOf();

                return convertToTime(b?.__meta?.timestamp) - convertToTime(a?.__meta?.timestamp);
            });

            if (tenantConfigs?.[0]?.language) {
                i18n.changeLanguage(tenantConfigs?.[0]?.language?.[0]);
            } else {
                i18n.changeLanguage(tenantConfigs?.[0]?.subdomain === 'highpoint' ? 'es' : 'en');
            }
            return {
                ...tenantConfigs?.[0],
                tenantType: tenantConfigs?.[0].tenantType || 'trial',
                currencySymbol:
                    tenantConfigs?.[0].currencySymbol && tenantConfigs?.[0].currencySymbol !== ' '
                        ? tenantConfigs?.[0].currencySymbol
                        : 'en'
            };
        } catch (err) {
            console.log(err);
            dispatch(logEventToBackEnd('GET_TENANT_INFO_API_ERROR'));
            return rejectWithValue(err);
        }
    }
);

export const getOrganizationConfig = createAsyncThunk(
    'globalState/getOrganizationConfig',
    async (_, { getState, rejectWithValue, dispatch }) => {
        const state: any = getState();

        const tenantId = state?.global?.globalConfig?.subdomain;
        try {
            const response = await uipApiInstance({
                method: 'GET',
                url: `/api/advice/getorganizationconfig?tenant=${tenantId}`,
                withCredentials: false
            });

            return response?.data?.tenantConfig?.[0];
        } catch (err) {
            console.log(err);
            dispatch(logEventToBackEnd('GET_ORGANIZATION_CONFIG_API_ERROR'));
            return rejectWithValue(err);
        }
    }
);

export const updateTenantInfo = createAsyncThunk(
    'globalState/updateTenantInfo',
    async (requestedPayload: any, { dispatch, rejectWithValue }) => {
        try {
            const response = await uipApiInstance({
                method: 'POST',
                url: `/api/advisorportal/updatetenantinfo`,
                withCredentials: false,
                data: requestedPayload
            });

            return response?.data;
        } catch (err) {
            console.log(err);
            dispatch(logEventToBackEnd('UPDATE_TENANT_INFO_API_ERROR'));
            return rejectWithValue(err);
        }
    }
);

export const fetchPortfolio = createAsyncThunk(
    'globalState/fetchPortfolio',
    async (_, { rejectWithValue, getState, dispatch }) => {
        try {
            const state: any = getState();

            const isAuthenticated = state.auth.isAuthenticated;
            const orgEmail = state.global.orgInfo?.orgEmail;
            const tenantId = state?.global?.globalConfig?.subdomain;

            if (!isAuthenticated) {
                return { portfolios: [], domainEmail: '' };
            }

            const response = await uipApiInstance({
                method: 'GET',
                url: `/api/advice/marketportfolios?tenant=${tenantId}`,
                withCredentials: false
            });

            const latestDate = moment
                .max(
                    response.data.data
                        .filter((y: any) => y.orgEmail === orgEmail)
                        .map((e: any) => moment(e.__meta.timestamp, 'YYYY-MM-DD HH:mm:ss.SSSS'))
                )
                .valueOf();

            const latestResponse = response.data.data.find(
                (x: any) =>
                    moment(x.__meta.timestamp, 'YYYY-MM-DD HH:mm:ss.SSSS').valueOf() === latestDate &&
                    x.orgEmail === orgEmail
            );

            return {
                portfolios: latestResponse.portfolioData?.data || latestResponse.portfolioData?.config?.data,
                domainEmail: latestResponse.orgEmail
            };
        } catch (err) {
            dispatch(logEventToBackEnd(`MARKET_PORFOLIOS_API_ERROR: ${err}`));
            return rejectWithValue(err);
        }
    }
);

export const fetchGoeConfig = createAsyncThunk(
    'globalState/fetchGoeConfig',
    async (_, { rejectWithValue, getState, dispatch }) => {
        try {
            const state: any = getState();

            const isAuthenticated = state.auth.isAuthenticated;

            if (!isAuthenticated) {
                return {};
            }

            const response = await uipApiInstance({
                method: 'GET',
                url: `/api/advice/getgoeconfig`,
                withCredentials: false,
                headers: {
                    generatepayloadonly: true,
                    clientemail: state?.global?.orgInfo?.orgEmail
                }
            });
            return response?.data?.body;
        } catch (err) {
            console.log(err);
            dispatch(logEventToBackEnd('GET_GOE_CONFIG_API_ERROR'));
            return rejectWithValue(err);
        }
    }
);

export const saveUserAgreementsSettings = createAsyncThunk(
    'globalState/saveUserAgreementsSettings',
    async (requestedPayload: any, { dispatch, rejectWithValue }) => {
        try {
            const response = await uipApiInstance({
                method: 'POST',
                url: '/api/advice/update_user_settings',
                withCredentials: false,
                data: requestedPayload
            });
            return { response: response?.data };
        } catch (err) {
            console.log(err);
            dispatch(logEventToBackEnd('UPDATE_USER_AGREEMENT_SETTINGS_ERROR'));
            return rejectWithValue(err);
        }
    }
);

const globalSlice = createSlice({
    name: 'globalState',
    initialState,
    reducers: {
        updateErrorModalState: (state, action: PayloadAction<any>) => {
            state.showErrorModal = action.payload.showErrorModal;
            state.error = action.payload.error;
        },
        updateAdminConfig: (state, action: PayloadAction<any>) => {
            state.multiTenancyRefresh = makeUseMemoRun();
            state.globalConfig = {
                ...state.globalConfig,
                styles: {
                    ...state.globalConfig.styles,
                    color: action.payload.color
                },
                tenantType: action.payload.tenantType,
                currencySymbol: action.payload.currencySymbol,
                termsOfUse: action.payload.termsOfUse,
                eula: action.payload.eula
            };
        },
        updateLogo: (state, action: PayloadAction<any>) => {
            state.globalConfig.logo = action.payload;
        },
        updateSubdomain: (state, action: PayloadAction<any>) => {
            state.globalConfig.subdomain = action.payload;
        },
        updateGlobalLoaderState: (state, action: PayloadAction<any>) => {
            state.globalLoader = action.payload;
        },
        handleOpenAgreementModal: (state, action: PayloadAction<any>) => {
            state.showAgreementModal = action.payload;
        },
        resetGlobalReducer: () => initialState
    },
    extraReducers: (builder) => {
        builder.addCase(logout, (state) => ({ ...initialState, globalConfig: state.globalConfig })),
            builder.addCase(fetchPortfolio.fulfilled, (state, action) => {
                state.loading = false;
                state.portfolio = action.payload.portfolios;
                state.domainEmail = action.payload.domainEmail;
                state.error = {};
                state.showErrorModal = false;
            }),
            builder.addCase(fetchPortfolio.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error;
                state.showErrorModal = true;
            }),
            builder.addCase(fetchGoeConfig.fulfilled, (state, action) => {
                state.loading = false;
                state.goeConfig = action.payload;
                state.error = {};
                state.showErrorModal = false;
            }),
            builder.addCase(fetchGoeConfig.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error;
                state.showErrorModal = true;
            }),
            builder.addCase(getTenantBasedConfig.pending, (state) => {
                state.globalLoader = true;
            }),
            builder.addCase(getTenantBasedConfig.fulfilled, (state, action) => {
                console.log('action payload: ', action.payload);
                state.globalLoader = false;
                state.globalConfig = action.payload;
                state.error = {};
                state.showErrorModal = false;
            }),
            builder.addCase(getTenantBasedConfig.rejected, (state, action) => {
                state.globalLoader = false;
                state.error = action.error;
                state.showErrorModal = true;
            });
        builder.addCase(getOrganizationConfig.fulfilled, (state, action) => {
            state.orgInfo = action.payload.orgInfo;
        }),
            builder.addCase(getOrganizationConfig.rejected, (state, action) => {
                state.error = action.error;
                state.showErrorModal = true;
            });
        builder.addCase(saveUserAgreementsSettings.pending, (state) => {
            state.loading = true;
        }),
            builder.addCase(saveUserAgreementsSettings.fulfilled, (state, action) => {
                console.log(action);
                state.loading = false;
                state.error = '';
                if (action?.payload?.response?.success) {
                    state.showAgreementModal = false;
                }
            }),
            builder.addCase(saveUserAgreementsSettings.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error;
            });
    }
});

export const {
    updateErrorModalState,
    updateAdminConfig,
    updateGlobalLoaderState,
    updateLogo,
    updateSubdomain,
    resetGlobalReducer,
    handleOpenAgreementModal
} = globalSlice.actions;

export default globalSlice.reducer;
