const CLIENT_ID = process.env.REACT_APP_OKTA_CLIENT_ID;
const ISSUER = `https://${process.env.REACT_APP_OKTA_DOMAIN}/oauth2/default`;
const REDIRECT_URI = window.origin + "/login/callback";

export default {
  oidc: {
    baseUrl: ISSUER.split("/oauth2")[0],
    clientId: CLIENT_ID,
    redirectUri: REDIRECT_URI,
    issuer: ISSUER,
    authParams: {
      issuer: ISSUER,
      dpop: true,
      pkce: true
    },
    // logo: isFranklinAdviceTenant ? FTLogo_Black : logo,
    // logoText: brandName // shows when logo fails to load.
  }
};